import { Component, HostListener, Input, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MEDIA_BREAKPOINT_MIN } from '../../../../constants/other.constant';
import { MenuId } from '../../../../enums/menu-id.enum';
import { MenuNavService } from '../../services/menu-nav.service';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrl: './base-page.component.scss'
})
export class BasePageComponent implements OnDestroy {
  isMobile: boolean = true;
  private navService = inject(MenuNavService);
  title: string = '';
  subscriptions: Subscription[] = [];
  @Input() isLoading: boolean = false;
  constructor(protected route: ActivatedRoute) {
    this.title = this.route?.snapshot?.data?.['title'];
    this.initNavMenu();
    this.getScreenSize();
  }

  @HostListener('window:resize')
  getScreenSize() {
    this.isMobile = window.innerWidth < MEDIA_BREAKPOINT_MIN.lg;
  }

  addSubscription(sub: Subscription): void {
    this.subscriptions.push(sub);
  }

  initNavMenu(): void {
    let route: ActivatedRoute | null = this.route;
    while( route ) {
      const menuId: MenuId | null = route.snapshot.data['menuId'];
      if (menuId) {
        this.navService.activeMenuId$.next(menuId);
        break;
      }
      route = route.parent;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }
}
