import { Component, ViewChild } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { MovieService } from '../../services/movie/movie.service';
import { SearchMovie } from '../../../../model/movie.model';
import { IApiResponseDetail } from '../../../../interface/common.interface';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { getReleaseYear } from '../../util/helper';
import { Router } from '@angular/router';
import { Params } from '../../../../constants/params.constant';
interface Item {
  id?: number,
  type: 'movie'|'cast'
  name: string,
  year?: number,
  genre?: string,
  image: string
}
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.scss'
})
export class SearchBoxComponent {
  keyword: string = '';
  isShowingResults: boolean = false;

  filteredGroups!: any[];

  constructor(private movieService: MovieService, private router: Router) {}

  @ViewChild(AutoComplete) searchBox?: AutoComplete

  navigateToMovieDetail(id: string) {
    this.router.navigateByUrl(`/pages/directory/detail/${id}`);
  }

  navigateToMovieDirectory(id:any,name:any) {
    const encodedObject = encodeURIComponent(JSON.stringify({id,name}));
    this.router.navigate(['/pages/directory'], {queryParams:{[Params.CAST]:encodedObject}})
  }
  
  search(): void {
    this.isShowingResults = !this.isShowingResults;
  }

  searchMovie(keyword: string = ''): void {
    this.movieService.searchMovies(keyword)
    .subscribe({
      next: (res: IApiResponseDetail<SearchMovie>) => {
        const {movies, casts} = res.response_output.detail;
        const groupedMovies: SelectItem<Item>[] = [];
        const groupedCasts: SelectItem<Item>[] = [];
        movies.forEach((item)=>{
          const year = getReleaseYear(item);
          const genre = item.movie_genre?.join('/')
          groupedMovies.push({value: {
            id: item.id,
            type: 'movie',
            name: item.title as string,
            year: year,
            genre: genre,
            image: item.image_title as string,
          }})
        })
        casts.forEach((item)=>{
          groupedCasts.push({value: {
            type: 'cast',
            id: item.id,
            name: item.name as string,
            image: item.image as string,
          }})
        })
        const resluts: SelectItemGroup[] = [];
        if (groupedMovies.length>0) resluts.push({label: 'MOVIES', value: 'movies', items: groupedMovies});
        if (groupedCasts.length>0) resluts.push({label: 'CASTS', value: 'casts', items: groupedCasts});
        this.filteredGroups = resluts;
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }

  ngModelChange(event:any) {
    if (event?.type === 'movie') {
      this.navigateToMovieDetail(event.id);
      this.clear();
    } else if (event?.type === 'cast') {
      this.navigateToMovieDirectory(event.id, event.name);
      this.clear();
    }
  }

  clear() {
    if (this.searchBox) {
      this.searchBox.clear();
      this.searchBox.focused = false;
    }
  }
}
