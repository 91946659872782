
<p-dialog 
    [dismissableMask]="true"
    [header]="header | translate"
    [modal]="true"
    [(visible)]="visible"
    (onHide)="visibleChange.emit(false)"
    position="bottom"
    [style]="{ width: '100%', margin: '0', bottom: '-1px'}"
    class="mobile-filter-dialog"
    [draggable]="false"
    >
    <ng-container *ngTemplateOutlet="popup"></ng-container>
    <!-- Footer Template -->
    <ng-template pTemplate="footer">
      <div class="flex justify-between gap-[12px] mt-[16px]">
        <app-cine-button
          buttonId="tbo-filter_button-back"
          class="col-span-2 mt-[4px] w-full max-w-[400px]"
          styleClass="cine-button-secondary"
          [label]="'COMMON.BACK'"
          (onClick)="visible = false; visibleChange.emit(visible)"
        ></app-cine-button>
        <app-cine-button
          buttonId="tbo-filter_button-apply"
          class="col-span-2 mt-[4px] w-full max-w-[400px]"
          styleClass="cine-button-primary"
          [label]="'COMMON.SAVE'"
          (onClick)="onSave.emit(value)"
        ></app-cine-button>
      </div>
    </ng-template>
</p-dialog>

<ng-template #popup>
  <div class="flex flex-col gap-[16px] py-[8px]">
    <div class="text-neutral-400 font-medium text-sm">
      {{subheader |translate}}
    </div>
    <div *ngFor="let option of options" class="flex items-center gap-[8px]">
      <input 
        type="radio" 
        [name]="subheader"
        [value]="option.value" 
        [(ngModel)]="value" 
        (ngModelChange)="valueChange.emit($event)"
        class="form-radio"
      />
      <label [for]="option.value" class="text-sm">{{ option.label | translate }}</label>
    </div>
    
  </div>
</ng-template>
