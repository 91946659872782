  <div class="sign-up-form mx-auto flex flex-col gap-[20px] ">

    <!-- Password -->
    <div>
      <app-input-control
        placeholder="SIGN_UP.PLACEHOLDER_PASSWORD"
        [ngForm]="form"
        [controlType]="'password'"
        fieldName="password"
        fieldNameKey="COMMON.NEW_PASSWORD"
        errorFieldNameKey="COMMON.PASSWORD"
        [excludeErrorKeys]="passwordIgnoreError"
      ></app-input-control>

      <app-password-tick-marks [passwordControl]="passwordCtrl"></app-password-tick-marks>
    </div>

    <!-- Confirm Password -->
    <app-input-control
        placeholder="SIGN_UP.PLACEHOLDER_CONFIRM_PASSWORD"
        [ngForm]="form"
        [controlType]="'password'"
        fieldName="confirmPassword"
        fieldNameKey="COMMON.CONFIRM_NEW_PASSWORD"
        errorFieldNameKey="COMMON.CONFIRM_PASSWORD"
        [excludeErrorKeys]="passwordIgnoreError"
      ></app-input-control>

  </div>
