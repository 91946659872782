<div class="pt-[40px]">
  <p-tabView>
    <!-- Admission tab -->
    <p-tabPanel [header]="'COMMON.ADMISSION' | translate">
      <app-movie-details-individual-chart
        #admissionChart
        [isLocked]="!isUserSubscribed"
        [title]="'COMMON.ADMISSION'"
        [xLabels]="dataAdmission.labels"
        [datasets]="dataAdmission.datasets"
        (countryTypeChanged)="initAdmissionChart()"
        (periodTypeChanged)="initAdmissionChart()"
        [yAxisTickCallback]="chartYaxistSeparatorComa"
      ></app-movie-details-individual-chart>

    </p-tabPanel>

    <!-- Gross tab -->
    <p-tabPanel [header]="'COMMON.GROSS' | translate">
      <app-movie-details-individual-chart
        #grossChart
        [isLocked]="!isUserSubscribed"
        [title]="'COMMON.GROSS'"
        [xLabels]="dataGross.labels"
        [datasets]="dataGross.datasets"
        (countryTypeChanged)="initGrossChart()"
        (periodTypeChanged)="initGrossChart()"
        [yAxisTickCallback]="chartYaxistCurrencyDollar"
      ></app-movie-details-individual-chart>
    </p-tabPanel>

    <!-- Showtimes tab -->
    <p-tabPanel [header]="'COMMON.SHOWTIMES' | translate">
      <app-movie-details-individual-chart
      #showtimesChart
        [isLocked]="!isUserSubscribed"
        [title]="'COMMON.SHOWTIMES'"
        [xLabels]="dataShowtimes.labels"
        [datasets]="dataShowtimes.datasets"
        (countryTypeChanged)="initShowtimesChart()"
        (periodTypeChanged)="initShowtimesChart()"
        [yAxisTickCallback]="chartYaxistSeparatorComa"
      ></app-movie-details-individual-chart>
    </p-tabPanel>

    <!-- Demographic -->
    <p-tabPanel [header]="'COMMON.DEMOGRAPHIC' | translate">
      <app-movie-details-individual-chart
        #demographicChart
        chartType="bar"
        [isLocked]="!isUserSubscribed"
        [title]="'COMMON.DEMOGRAPHIC'"
        [desc]="'DEMOGRAPHIC.DESC'"
        [xLabels]="dataDemographic.labels"
        [datasets]="dataDemographic.datasets"
        (countryTypeChanged)="initDemographicChart()"
        (periodTypeChanged)="initDemographicChart()"
        [yAxisTickCallback]="chartYaxistSeparatorComa"
      ></app-movie-details-individual-chart>
    </p-tabPanel>
  </p-tabView>
</div>

@if(showCompareLink && movies.length > 0) {
  <!-- Compare with other movies link -->
  <app-compare-movie-link [movies]="movies"></app-compare-movie-link>
}
