import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { isEmptyObject, objectToArray } from './helper';

export const validatePwdLength = (p: string): boolean => {
  return p?.trim().length >= 8;
};

export const validatePwdOneNo = (p: string): boolean => {
  return /\d/.test(p);
};

export const validatePwdOneUpper = (p: string): boolean => {
  return /[A-Z]/.test(p);
};

export const validatePwdOneSpecial = (p: string): boolean => {
  return /[!@#$%^&*]/.test(p);
};

export const validatorPwdLength = (
  control: AbstractControl
): ValidationErrors | null => {
  return validatePwdLength(control?.value)
    ? null
    : { passwordLength: { value: control?.value } };
};

export const validatorPwdDigit = (
  control: AbstractControl
): ValidationErrors | null => {
  return validatePwdOneNo(control?.value)
    ? null
    : { noDigit: { value: control?.value } };
};

export const validatorPwdUppercase = (
  control: AbstractControl
): ValidationErrors | null => {
  return validatePwdOneUpper(control?.value)
    ? null
    : { noUppercase: { value: control?.value } };
};

export const validatorPwdSpecialChar = (
  control: AbstractControl
): ValidationErrors | null => {
  return validatePwdOneSpecial(control?.value)
    ? null
    : { noSpecial: { value: control?.value } };
};

export const validateEmail = (e: string): boolean => {
  const regex = /^[\w\-\+]+(\.[\w\-\+]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return regex.test(e);
};

export const validatorEmail = (
  control: AbstractControl
): ValidationErrors | null => {
  return !control?.value || validateEmail(control?.value)
    ? null
    : { email: { value: control?.value } };
};

export const extractValidationErrors = (
  control: FormControl,
  fieldName: string,
  showOnDirty: boolean = false,
  excludeKeys: string[] = [],
  customErrors: any = {}
): any[] => {
  const errors =
    control?.touched || (showOnDirty && control?.dirty) ? control?.errors : [];
  return objectToArray(errors)
    .filter(([key, val]) => !excludeKeys.includes(key.toUpperCase()))
    .map(([key, val]) => {
      return {
        errorKey: customErrors.hasOwnProperty(key)? customErrors[key] : `ERROR_MSG.${key.toUpperCase()}`,
        params: { ...val, fieldName },
      };
    });
};

export const validatePhone = (e: string): boolean => {
  const regex = /^\d{8,13}$/;
  return regex.test(e);
};

export const validatorPhone = (
  control: AbstractControl
): ValidationErrors | null => {
  return !control?.value || validatePhone(control?.value)
    ? null
    : { phone: { value: control?.value } };
};

export const validateFormGroupForMathingPassword = (
  form: FormGroup,
  passwordFieldname: string,
  confirmPasswordFieldname: string
) => {
  const passwordCtrl = form.get(passwordFieldname);
  const confirmPasswordCtrl = form.get(confirmPasswordFieldname);
  form.addValidators([
    (): ValidationErrors | null => {
      const passwordMismatch =
        confirmPasswordCtrl?.value &&
        passwordCtrl?.value !== confirmPasswordCtrl?.value;
      const errors = { ...confirmPasswordCtrl?.errors };
      if (passwordMismatch) {
        displayFieldErrors(confirmPasswordCtrl as FormControl, {
          ...errors,
          confirmPassword: true,
        });
      } else {
        delete errors['confirmPassword'];
        confirmPasswordCtrl?.setErrors(isEmptyObject(errors)? null : errors);
      }
      return passwordMismatch ? { passwordMismatch } : null;
    },
  ]);
};

export const displayFieldErrors = (
  control: FormControl,
  errors: ValidationErrors | null
): void => {
  control.markAsDirty();
  control.markAsTouched();
  control.setErrors(errors);
};


export const maxDateValidator = (maxDate: Date) => {
  return (control: any) => {
    const inputDate = new Date(control.value);
    if (inputDate > maxDate) {
      return { maxDate: true };
    }
    return null;
  };
}

export const minDateValidator = (minDate: Date) => {
  return (control: any) => {
    const inputDate = new Date(control.value);
    if (inputDate < minDate) {
      return { minDate: true };
    }
    return null;
  };
}
