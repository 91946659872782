import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-animation',
  templateUrl: './loader-animation.component.html',
  styleUrl: './loader-animation.component.scss'
})
export class LoaderAnimationComponent {
  @Input() isOverlay: boolean = true;
}
