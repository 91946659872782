<div class="w-[768px] h-[516px] bg-white rounded py-[12px] px-[16px] border border-neutral-100 relative z-50">
  <!-- Movie List -->
  <div [translate]="'COMMON.MOVIES'" class="uppercase text-xs text-neutral-400"></div>

  <!-- List of Movie items -->
  @for (movie of movies; track movie.id) {
    <div class="flex flex-row items-center gap-[12px] py-[12px]" >
      <app-image-card [url]="movie.url" class="min-w-[40px] min-h-[40px]"></app-image-card>
      <div class="flex flex-col w-full">
        <span class="font-medium text-sm text-neutral-700">{{ movie.title }}</span>
        <span class="text-sm text-neutral-400">{{ movie.year }} • {{ movie.genre }}</span>
      </div>
      <i class="pi pi-angle-right text-neutral-500"></i>
    </div>
  }


  <!-- Cast List -->
  <div [translate]="'COMMON.CASTS'" class="uppercase text-xs text-neutral-400 pt-[12px]"></div>

  <!-- List of Movie items -->
  @for (cast of casts; track cast.id) {
    <div class="flex flex-row items-center gap-[12px] py-[12px]" >
      <app-image-card [url]="cast.url" class="min-w-[40px] min-h-[40px]"></app-image-card>
      <div class="flex flex-col w-full">
        <span class="font-medium text-sm text-neutral-700">{{ cast.name }}</span>
      </div>
    </div>
  }

</div>
