<div (click)="handleClickEvent($event)" class="cursor-pointer flex flex-col gap-[16px] p-0-24 card-with-image">
  <!-- Movie Poster -->
  <app-image-card
    [url]="movie.image_title!"
    frameStyleClass="min-w-[280px] min-h-[210px] max-w-[280px] max-h-[210px]"
    class="min-w-[280px] min-h-[210px] max-w-[280px] max-h-[210px]"
  ></app-image-card>

  <!-- Details to below of the Image -->
  <div class="flex flex-col text-description-xs gap-[4px] card-text-section">
    <!-- Movie Title -->
    <div class="text-movie-title-lg truncate">{{ movie.title }}</div>

    <!-- Movie Genre -->
    <div class="h-[16px]">{{ getGenre() }}</div>
    <div class="h-[16px]">{{ getDuration() }}</div>
    @if (showYear) {
      @if (hideDayDate) {
        <div>{{ getYear() | date: 'YYYY-MM'}}</div>
      } @else {
        <div class="h-[16px]">{{ getYear() }}</div>
      }
    }

  </div>
</div>
