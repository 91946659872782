export const FakeChartData = [

  {
    "id": 2227,
    "title": "Ipar Adalah Maut",
    "data": [
      {
        "date": "2024-06-17",
        "periode": "2024-06-17",
        "value": 3060
      },
      {
        "date": "2024-06-18",
        "periode": "2024-06-18",
        "value": 2589
      },
      {
        "date": "2024-06-19",
        "periode": "2024-06-19",
        "value": 3150
      },
      {
        "date": "2024-06-20",
        "periode": "2024-06-20",
        "value": 3043
      },
      {
        "date": "2024-06-21",
        "periode": "2024-06-21",
        "value": 3333
      },
      {
        "date": "2024-06-22",
        "periode": "2024-06-22",
        "value": 3505
      },
      {
        "date": "2024-06-23",
        "periode": "2024-06-23",
        "value": 3532
      },
      {
        "date": "2024-06-24",
        "periode": "2024-06-24",
        "value": 3038
      },
      {
        "date": "2024-06-25",
        "periode": "2024-06-25",
        "value": 3632
      },
      {
        "date": "2024-06-26",
        "periode": "2024-06-26",
        "value": 3297
      },
      {
        "date": "2024-06-27",
        "periode": "2024-06-27",
        "value": 2708
      },
      {
        "date": "2024-06-28",
        "periode": "2024-06-28",
        "value": 2818
      },
      {
        "date": "2024-06-29",
        "periode": "2024-06-29",
        "value": 2736
      },
      {
        "date": "2024-06-30",
        "periode": "2024-06-30",
        "value": 2808
      },
      {
        "date": "2024-07-01",
        "periode": "2024-07-01",
        "value": 2748
      },
      {
        "date": "2024-07-02",
        "periode": "2024-07-02",
        "value": 2814
      },
      {
        "date": "2024-07-03",
        "periode": "2024-07-03",
        "value": 2366
      },
      {
        "date": "2024-07-04",
        "periode": "2024-07-04",
        "value": 1729
      },
      {
        "date": "2024-07-05",
        "periode": "2024-07-05",
        "value": 1576
      },
      {
        "date": "2024-07-06",
        "periode": "2024-07-06",
        "value": 1537
      },
      {
        "date": "2024-07-07",
        "periode": "2024-07-07",
        "value": 1461
      },
      {
        "date": "2024-07-08",
        "periode": "2024-07-08",
        "value": 1467
      },
      {
        "date": "2024-07-09",
        "periode": "2024-07-09",
        "value": 1433
      },
      {
        "date": "2024-07-10",
        "periode": "2024-07-10",
        "value": 1152
      },
      {
        "date": "2024-07-11",
        "periode": "2024-07-11",
        "value": 795
      },
      {
        "date": "2024-07-12",
        "periode": "2024-07-12",
        "value": 796
      },
      {
        "date": "2024-07-13",
        "periode": "2024-07-13",
        "value": 778
      },
      {
        "date": "2024-07-14",
        "periode": "2024-07-14",
        "value": 766
      },
      {
        "date": "2024-07-15",
        "periode": "2024-07-15",
        "value": 729
      },
      {
        "date": "2024-07-16",
        "periode": "2024-07-16",
        "value": 732
      },
      {
        "date": "2024-07-17",
        "periode": "2024-07-17",
        "value": 620
      }
    ]
  },
  {
    "id": 2247,
    "title": "Despicable Me 4",
    "data": [
      {
        "date": "2024-06-17",
        "periode": "2024-06-17",
        "value": 0
      },
      {
        "date": "2024-06-18",
        "periode": "2024-06-18",
        "value": 0
      },
      {
        "date": "2024-06-19",
        "periode": "2024-06-19",
        "value": 0
      },
      {
        "date": "2024-06-20",
        "periode": "2024-06-20",
        "value": 0
      },
      {
        "date": "2024-06-21",
        "periode": "2024-06-21",
        "value": 0
      },
      {
        "date": "2024-06-22",
        "periode": "2024-06-22",
        "value": 0
      },
      {
        "date": "2024-06-23",
        "periode": "2024-06-23",
        "value": 0
      },
      {
        "date": "2024-06-24",
        "periode": "2024-06-24",
        "value": 0
      },
      {
        "date": "2024-06-25",
        "periode": "2024-06-25",
        "value": 0
      },
      {
        "date": "2024-06-26",
        "periode": "2024-06-26",
        "value": 0
      },
      {
        "date": "2024-06-27",
        "periode": "2024-06-27",
        "value": 0
      },
      {
        "date": "2024-06-28",
        "periode": "2024-06-28",
        "value": 0
      },
      {
        "date": "2024-06-29",
        "periode": "2024-06-29",
        "value": 0
      },
      {
        "date": "2024-06-30",
        "periode": "2024-06-30",
        "value": 361
      },
      {
        "date": "2024-07-01",
        "periode": "2024-07-01",
        "value": 411
      },
      {
        "date": "2024-07-02",
        "periode": "2024-07-02",
        "value": 446
      },
      {
        "date": "2024-07-03",
        "periode": "2024-07-03",
        "value": 2989
      },
      {
        "date": "2024-07-04",
        "periode": "2024-07-04",
        "value": 3465
      },
      {
        "date": "2024-07-05",
        "periode": "2024-07-05",
        "value": 3125
      },
      {
        "date": "2024-07-06",
        "periode": "2024-07-06",
        "value": 3345
      },
      {
        "date": "2024-07-07",
        "periode": "2024-07-07",
        "value": 3338
      },
      {
        "date": "2024-07-08",
        "periode": "2024-07-08",
        "value": 3278
      },
      {
        "date": "2024-07-09",
        "periode": "2024-07-09",
        "value": 3210
      },
      {
        "date": "2024-07-10",
        "periode": "2024-07-10",
        "value": 2187
      },
      {
        "date": "2024-07-11",
        "periode": "2024-07-11",
        "value": 1617
      },
      {
        "date": "2024-07-12",
        "periode": "2024-07-12",
        "value": 1576
      },
      {
        "date": "2024-07-13",
        "periode": "2024-07-13",
        "value": 1719
      },
      {
        "date": "2024-07-14",
        "periode": "2024-07-14",
        "value": 1823
      },
      {
        "date": "2024-07-15",
        "periode": "2024-07-15",
        "value": 1709
      },
      {
        "date": "2024-07-16",
        "periode": "2024-07-16",
        "value": 1564
      },
      {
        "date": "2024-07-17",
        "periode": "2024-07-17",
        "value": 1037
      }
    ]
  },

]


export const FakeDemographicChartData = [
  {
    "id": 2227,
    "title": "Ipar Adalah Maut",
    "data": [
      {
        "date": "Jun-17 - Jun-23",
        "periode": "Jun-17 - Jun-23",
        "female": 1,
        "male": 19
      },
      {
        "date": "Jun-24 - Jun-30",
        "periode": "Jun-24 - Jun-30",
        "female": 4,
        "male": 14
      },
      {
        "date": "Jul-01 - Jul-07",
        "periode": "Jul-01 - Jul-07",
        "female": 4,
        "male": 6
      },
      {
        "date": "Jul-08 - Jul-14",
        "periode": "Jul-08 - Jul-14",
        "female": 0,
        "male": 5
      }
    ]
  }
]
