import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpContentType } from '../enums/http-content-type.enum';
import { IApiResponseDetail, IApiResponseList, IListResponse, IParamsTableData } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(protected http: HttpClient) {}

  createDefaultParamsAndOptions(params?: any, spinneroff: boolean = false, contentType: string | null = HttpContentType.Json, additionalHeaders: any = {}): any {
    const requestOptions = {
      headers: contentType ? new HttpHeaders({
        'Content-Type': contentType,
        ...additionalHeaders,
      }) : undefined,
    };
    params = {
      ...params,
      spinneroff: spinneroff? '1' : '0'
    }
    return { params, ...requestOptions };
  }

  getRequest(url: string, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.http.get(url, this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.Json, additionalHeaders));
  }

  getRequestSimplified<T>(url: string, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.getRequest(url, params, spinneroff, additionalHeaders).pipe(
      map((res: IApiResponseDetail<T>) => {
        return res.response_output.detail;
      })
    );
  }

  postRequestRaw(url: string, body?: any, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.http.post(url, body, {
      ...this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.Json, additionalHeaders),
      responseType: 'json',
    });
  }

  postRequestSimplified<T>(url: string, body?: any, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.postRequestRaw(url, body, params, spinneroff, additionalHeaders).pipe(
      map((res: IApiResponseDetail<T>) => {
        return res.response_output.detail;
      })
    )
  }

  putRequestRaw(url: string, body?: any, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.http.put(url, body, this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.Json, additionalHeaders));
  }

  putRequestFormData(url: string, body?: any, params?: any, spinneroff: boolean = false): Observable<any> {
    return this.http.put(url, body); //, this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.FormData));
  }

  putRequestSimplified<T>(url: string, body?: any, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.putRequestRaw(url, body, params, spinneroff, additionalHeaders).pipe(
      map((res: IApiResponseDetail<T>) => {
        return res.response_output.detail;
      })
    )
  }

  deleteRequest(url: string, params?: any,  spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.http.delete(url, this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.Json, additionalHeaders));
  }

  postRequestFile(url: string, file: FormData, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.http.post(url, file, this.createDefaultParamsAndOptions(params, spinneroff, null, additionalHeaders));
  }

  getRequestFile(url: string, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<File> {

    return this.http
      .get(url, {
        observe: 'response',
        ...this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.Octet, additionalHeaders),
        responseType: 'blob',
      }).pipe(
        map((res: any)=>{
          let filename = 'download'
          const content_disposition = res.headers.get('Content-Disposition');
          const extract = content_disposition?.toString()?.split(';')[1];
          filename = extract?.split('=')[1]?.trim()?.replace(/(^"|"$)/g, '');
          const blob = new Blob([res.body], {
            type: 'application/octet-stream',
          });
          const file = new File([blob], filename, { type: blob.type });
          return file;
        })
      );
  }

  getRequestDownloadFile(url: string, params?: any, spinneroff: boolean = false, additionalHeaders: any = {}): Observable<any> {
    return this.http
      .get(url, {
        observe: 'response',
        ...this.createDefaultParamsAndOptions(params, spinneroff, HttpContentType.Octet, additionalHeaders),
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((res: any) => {
          const content_disposition = res.headers.get('Content-Disposition');
          const extract = content_disposition.toString().split(';')[1];
          const filename = extract
            .split('=')[1]
            .trim()
            .replace(/(^"|"$)/g, '');
          const blob = new Blob([res.body], {
            type: 'application/octet-stream',
          });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64 = reader.result;
            const source = base64!.toString();
            let link: any = document.createElement('a');
            link.id = 'pdfLink' + filename;
            link.href = source;
            link.download = filename.toString();
            link.click();
            link = null;
          };
        })
      );
  }


  getList<T>(url: string, params: IParamsTableData = {}, spinneroff = false): Observable<IListResponse<T>['list']> {
    return this.getRequest(url, params, spinneroff).pipe(
      map((res: IApiResponseList<T>) => {
        return res.response_output.list;
      })
    )
  }


}
