<div class="cinepoint-webapp rounded-lg bg-white p-[24px] flex flex-col text-center">
  <span
    class="text-neutral-900 text-[18px]/[28px] font-medium pb-[4px]"
    [translate]="'CONFIRM_SAVE.TITLE'"
  ></span>
  <span
    class="text-neutral-500 text-sm break-words mb-[32px]"
    [translate]="'CONFIRM_SAVE.PROMPT'"
  ></span>
  <div class="flex gap-[8px] items-center">
    <app-cine-button
      buttonId="save-confirm-dialog_button-arrow-right"
      styleClass="cine-button-secondary"
      class="w-1/2 whitespace-nowrap"
      (onClick)="cancel()"
      label="COMMON.CANCEL"
    ></app-cine-button>
    <app-cine-button
      buttonId="save-confirm-dialog_button-arrow-right"
      styleClass="cine-button-primary"
      class="w-1/2 whitespace-nowrap"
      (onClick)="continue()"
      label="COMMON.SAVE"
    ></app-cine-button>
  </div>
</div>
