<div class="flex flex-row gap-[12px] justify-center {{frameStyleClass}}">
  <div class="flex flex-col justify-center">
    <span class="label-cinepoint text-neutral-900 text-xs">Cinepoint</span>
    <span class="label-flash text-primary-500 text-xs italic font-bold">Flash
      @for (i of [].constructor(2); track $index) {
        <i class="icon-flash bg-primary-500 !w-[12px] !h-[12px]"></i>
      }
    </span>
  </div>
  <span class="label-score text-neutral-900 font-medium text-[20px] lg:text-4xl/[48px]">{{score}}</span>
</div>
