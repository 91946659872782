import { Component } from '@angular/core';
import { BaseCardMovieComponent } from '../../../../shared/components/base-card-movie/base-card-movie.component';
import { getReleaseYear } from '../../util/helper';

@Component({
  selector: 'app-card-movie-detail-vertical',
  templateUrl: './card-movie-detail-vertical.component.html',
  styleUrl: './card-movie-detail-vertical.component.scss'
})
export class CardMovieDetailVerticalComponent extends BaseCardMovieComponent{
  getYear():number  {
    return getReleaseYear(this.movie);
  }

  getGenre(): string[] {
    return this.movie?.movie_genre || [];
  }

}
