import { Component } from '@angular/core';
import { BaseCardMovieComponent } from '../../../../shared/components/base-card-movie/base-card-movie.component';

@Component({
  selector: 'app-card-movie-synopsis',
  templateUrl: './card-movie-synopsis.component.html',
  styleUrl: './card-movie-synopsis.component.scss'
})
export class CardMovieSynopsisComponent extends BaseCardMovieComponent{

}
