import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import { TableLazyLoadEvent } from 'primeng/table';
import { finalize } from 'rxjs';
import { TAB_BO_DAILY, TAB_BO_DAILY_LABEL, TAB_BO_MONTHLY, TAB_BO_MONTHLY_LABEL, TAB_BO_WEEKLY, TAB_BO_WEEKLY_LABEL, TAB_BO_YEARLY, TAB_BO_YEARLY_LABEL } from '../../../../constants/label.constants';
import { CountryType } from '../../../../enums/country-type.enum';
import { PeriodType } from '../../../../enums/period-type';
import { IListResponse } from '../../../../interface/common.interface';
import { Movie } from '../../../../model/movie.model';
import { DataLayerService } from '../../../../shared/services/data-layer.service';
import { MovieService } from '../../services/movie/movie.service';
import { getClosestPreviousSunday, getLastWeekMonday, getYesterday } from '../../util/date.util';
import { createCountryTypeOptions } from '../../util/helper';
import { convertLazyLoadEventToQueryParam } from '../../util/table.util';
import { BasePageComponent } from '../base-page/base-page.component';
import { CineTableComponent } from '../cine-table/cine-table.component';

@Component({
  selector: 'app-top-box-office',
  templateUrl: './top-box-office.component.html',
  styleUrl: './top-box-office.component.scss',
})
export class TopBoxOfficeComponent extends BasePageComponent implements OnInit {

  startDate(period: PeriodType): Date {
    switch(period) {
      case PeriodType.Daily: return getYesterday(new Date());
      case PeriodType.Weekly: return getLastWeekMonday(new Date());
      case PeriodType.Monthly: return startOfMonth(new Date());
      default: return startOfYear(new Date());
    }
  }

  endDate(period: PeriodType): Date {
    switch(period) {
      case PeriodType.Weekly: return getClosestPreviousSunday(new Date());
      case PeriodType.Monthly: return endOfMonth(new Date());
      default: return endOfYear(new Date());
    }

  }
  countryTypes = createCountryTypeOptions();

  commonTabData: {
    countryType: CountryType,
    boxOffice: Movie[],
    isLoading: boolean,
    totalRecords: number,
    currentPage: number,
    pageSize: number
  } = {
    countryType: CountryType.All,
    boxOffice: [],
    isLoading: false,
    totalRecords: 0,
    currentPage: 0,
    pageSize: 10
  };

  tabs: any[] = [
    {
      title: 'COMMON.DAILY',
      period: PeriodType.Daily,
      ...this.commonTabData
    },
    {
      title: 'COMMON.WEEKLY',
      period: PeriodType.Weekly,
      ...this.commonTabData
    },
    {
      title: 'COMMON.MONTHLY',
      period: PeriodType.Monthly,
      ...this.commonTabData
    },
    {
      title: 'COMMON.YEARLY',
      period: PeriodType.Yearly,
      ...this.commonTabData
    },
  ];

  activeTabIndex: number = 0;
  @ViewChild(CineTableComponent) table?: CineTableComponent;

  constructor(
    protected override route: ActivatedRoute,
    private movieService: MovieService,
    private dataLayerService: DataLayerService,
  ) {
    super(route);
  }

  admissionLabel: string = TAB_BO_DAILY_LABEL;
  tabName: string = TAB_BO_DAILY;

  ngOnInit(): void {
    this.dataLayerService.logTopBoxOfficeTabView(this.tabName);
  }

  setAdmissionLabel(evt: any): void {
    switch(evt.index) {
      case 1:
        this.admissionLabel = TAB_BO_WEEKLY_LABEL;
        this.tabName = TAB_BO_WEEKLY;
        break;
      case 2:
        this.admissionLabel = TAB_BO_MONTHLY_LABEL;
        this.tabName = TAB_BO_MONTHLY;
        break;
      case 3:
        this.admissionLabel = TAB_BO_YEARLY_LABEL
        this.tabName = TAB_BO_YEARLY;
        break;
      default:
        this.admissionLabel = TAB_BO_DAILY_LABEL;
        this.tabName = TAB_BO_DAILY;
    }
    this.dataLayerService.logTopBoxOfficeTabView(this.tabName);
  }

  loadData(tabData: any, event: TableLazyLoadEvent): void {
    tabData.isLoading = true;
    this.addSubscription(
      this.movieService.getTopBoxOffice(
        tabData.period,
        tabData.countryType,
        convertLazyLoadEventToQueryParam(event), true)
        .pipe(finalize(()=>{
          tabData.isLoading = false;
        }))
        .subscribe({
          next: (res: IListResponse<Movie>['list']) => {
            tabData.boxOffice = res.content;
            tabData.totalRecords = res.pagination.total;
          }
        })
    );
  }
}
