export const Messages = {
  OTP_RESENT: 'OTP_RESENT',
  OTP_SEND_ERROR: 'OTP_SEND_ERROR',
  OTP_REACHED_LIMIT: 'OTP_REACHED_LIMIT',
  VERIF_LINK_RESENT: 'VERIF_LINK_RESENT',
  VERIF_LINK_RESEND_ERROR: 'VERIF_LINK_RESEND_ERROR',
  ERROR_GET_SUBSCRIPTION: 'ERROR_GET_SUBS',
  ERROR_LOGIN: 'ERROR_LOGIN',
  SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
  SUBSCRIPTION_CANCEL_ERROR: 'SUBSCRIPTION_CANCEL_ERROR',
  SUBSCRIPTION_ENDED: 'SUBSCRIPTION_ENDED',
  SUBSCRIPTION_NOTEXIST: 'SUBSCRIPTION_NOTEXIST',
  CHANGE_PASSWORD_SAVED: 'CHANGE_PASSWORD_SAVED',
  CHANGE_EMAIL_SAVED: 'CHANGE_EMAIL_SAVED',
  PERSONAL_INFO_SAVED: 'PERSONAL_INFO_SAVED',
  PERSONAL_INFO_ERROR: 'PERSONAL_INFO_ERROR',
}
