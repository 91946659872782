<div (click)="handleClickEvent($event)" class="cursor-pointer flex flex-col items-center gap-[16px] card-with-image p-[4px] w-[162px] md:w-[228px] min-h-[214px] md:min-h-[244px]">
  <!-- Movie Poster -->


  <div class="min-w-[158px] md:min-w-[224px] min-h-[211px] md:min-h-[240px] max-w-[158px] md:max-w-[224px] max-h-[211px] md:max-h-[240px]">
    <app-image-card
      [url]="movie.image_title!"
      fallbackImageType="movie_poster"
      frameStyleClass="min-w-[158px] md:min-w-[224px] min-h-[211px] md:min-h-[240px] max-w-[158px] md:max-w-[224px] max-h-[211px] md:max-h-[240px]"
    ></app-image-card>
  </div>

  <!-- Details to the Bottom of the Image -->
  <div class="mt-auto flex flex-col text-description-xs gap-[4px] card-text-section w-full">
    @if(movie.title) {

    <!-- Movie Genre -->
    <div class="min-h-[calc(1rem+10px)] flex flex-wrap gap-[8px] h-[26px]">
      @for (genre of getGenre().slice(0, 2); track $index) {
        <app-movie-genre-pill [label]="genre"></app-movie-genre-pill>
      }
      @if (getGenre().length > 2) {
        <div class="w-[26px] h-[26px] bg-neutral-50 rounded-full flex justify-center items-center"
          [pTooltip]="getGenre().join(', ')"
          [fitContent]="true"
          [tooltipZIndex]="'900'"
          [autoHide]="false"
          tooltipStyleClass="genre-tooltip"
          [tooltipPosition]="'bottom'"
        >
          <i class="pi pi-ellipsis-v text-neutral-500 text-[12px] !font-semibold rounded-full"
          ></i>
        </div>

      }
    </div>

    <!-- Movie Title -->
    <div class="mt-auto text-movie-title-lg truncate whitespace-pre-wrap h-[28px]">{{ movie.title }}</div>

    <!-- Movie Year -->
    <div class="text-neutral-500 text-sm h-[20px]">{{ getYear() }}</div>
    }
  </div>
</div>
