<div
  (click)="onClicked.emit($event)"
  class="cursor-pointer rounded-[16px] p-[16px] h-full
    border-[1.5px] border-dashed border-primary-500 grow
    bg-neutral-50 flex flex-col items-center justify-center
    {{styleClass}}">
  <div class="w-[48px] h-[48px] flex items-center justify-center
    rounded-full border-[1.5px] border-dashed border-primary-500 ">
    +
  </div>
  <span
    class="text-primary-500 text-center mt-[16px]"
    [translate]="label">
  </span>
</div>
