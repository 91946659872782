import { Component } from '@angular/core';
import { CardMovieDetailHorizontalComponent } from '../card-movie-detail-horizontal/card-movie-detail-horizontal.component';

@Component({
  selector: 'app-card-movie-upcoming',
  templateUrl: './card-movie-upcoming.component.html',
  styleUrl: './card-movie-upcoming.component.scss',
})
export class CardMovieUpcomingComponent extends CardMovieDetailHorizontalComponent {

}
