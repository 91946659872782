import { ComparisonPeriod } from '../enums/comparison-period.enum';
import { CountryType } from '../enums/country-type.enum';

export class Movie {
  id: number = 0;
  title?: string = '';
  movie_genre?: string[] = [];
  duration?: number = 0;
  description?: string = '';
  release_date?: string;
  release_year?: number;
  url?: string = '';
  image_title?: string = '';
  admission?: number = 0; // weekly admission
  change?: number = 0;
  rank?: MovieRank;
  score?: number = 0;
  showtimes?: any;
  total_admission?: number = 0;
  type?: CountryType;
  language?: string = '';
  production_status?: string = '';
  country?: string = '';
  rating?: string = '';
  rating_category?: string|string[] = '';
  casts?: CastList[] = [];
  gross?: number = 0;
  trailer_url?: string = '';
  similar_movies?: Movie[] = [];
  user_ratings?: UserRating[] = [];
  playing_at?: Cinema[] = [];
  /* This array is for displaying the 7-Day & 14-Day admission & gross comparison between this movie and others */
  comparison?: MovieForComparison[] = [];

}

export class DatePeriod {
  date_start?: Date;
  date_end?: Date;
}

export class MovieBoxOfficeInfo extends Movie {
  date_period: string = '';
  date_start: string = '';
  date_end: string =  '';
  datePeriodComposite?: DatePeriod;
}

export class MovieForComparison extends Movie {
  other_movie?: Movie;
  periode?: ComparisonPeriod;
}

export class MovieDailyShowtime extends Movie {
  data: ShowtimeData[] = [];
}

export class ShowtimeData {
  data: string = '';
  periode: string = '';
  value: number = 0;
}

export class Cinema {
  title: string = '';
  link: string = '';
  image: string = '';
}

export class UserRating {
  rating: number = 0;
  value: number = 0;
}
export class MovieRank {
  current_rank: number = 0;
  last_rank: number = 0;
}

export class Cast {
  id?: number;
  name?: string = '';
  image?: string = '';
  role?: string = '';
}

export class CastList {
  role?: string = '';
  names?: string[] = [];
}

export class SearchMovie {
  movies: Movie[] = [];
  casts: Cast[] = [];
}

export class MovieGenreOrRating {
  id: number = 0;
  short_name: string = '';
  long_name: string = '';
}
