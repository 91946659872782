import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { extractYouTubeVideoId } from '../../util/youtube.util';
import { BasePageComponent } from '../base-page/base-page.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-youtube-player-dialog',
  templateUrl: './youtube-player-dialog.component.html',
  styleUrl: './youtube-player-dialog.component.scss',
})
export class YoutubePlayerDialogComponent extends BasePageComponent implements OnInit {
  @Input() url: string = '';
  @Input() width: number = window.innerWidth;
  @Input() height: number = 0;

  videoId: string = '';

  constructor(
    protected override route: ActivatedRoute,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {super(route)}

  ngOnInit(): void {
    this.url = this.url || this.config.data['url'];
    this.videoId = this.url !== ''? extractYouTubeVideoId(this.url) : '';
    setTimeout(() => {
      this.config.showHeader = false;
      this.config.styleClass = 'dialog-no-padding';
      this.config.dismissableMask = true;
    }, 0);
    if ((this.config.width && this.config.height)||(this.config.data['width']&&this.config.data['height'])) {
      this.width = parseInt(this.config.width ?? '0') ?? this.config.data['width'];
      this.height = parseInt(this.config.height ?? '0') ?? this.config.data['height'];
    } else {
      this.width = this.isMobile ? window.innerWidth : window.innerWidth/2;
      this.height = this.width * (9 / 16); // 16:9 current youtube aspect ratio
      console.log(this.width, this.height);
    }
  }
}
