import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { extractValidationErrors } from '../../util/validator.util';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrl: './field-errors.component.scss',
})
export class FieldErrorsComponent {
  @Input() form!: FormGroup;
  @Input() fieldName!: string;
  @Input() fieldNameKey: string = '';
  @Input() showOnDirty: boolean = false;
  @Input() excludeKeys: string[] = [];
  @Input() isError: boolean = true;
  @Input() showIcon: boolean = false;
  @Input() customErrors: any = {};

  get iconClass(): string {
    return this.isError ? 'pi pi-times' : 'pi pi-check';
  }
  constructor(private translate: TranslateService) {}

  getErrors(): any[] {
    return extractValidationErrors(
      this.form.get(this.fieldName) as FormControl,
      this.fieldName,
      this.showOnDirty,
      this.excludeKeys,
      this.customErrors,
    ).map((err) => {
      return {
        errorKey: err.errorKey,
        params: {
          ...err.params,
          fieldName: this.translate.instant(this.fieldNameKey),
        },
      };
    });
  }
}
