
export enum Colour {
  Red = '#C30118',
  Pink300 = '#f38ec0',
  Yellow = '#DAC85C',
  Brown = '#806566',
  Green800 = '#136c34',
  Primary300 = '#85b2f9',
  Orange = '#FDA410',
  Purple = '#740EAB',
  Green = '#35B2D3',
  Secondary500 = '#F2459B',
  Primary800 = '#1B677C',
  Info500 = '#0F4F9E',
  DarkBlue = '#2B80AF',
  Violet = '#c680ff',
  ToscaGreen = '#00ffec',
  Green300 = '#76db9b',
  Pink800 = '#822854',
}


export const DemographicChartColours = [
  {male: Colour.Primary800, female: Colour.Primary300},
  {male: Colour.Pink800, female: Colour.Pink300},
  {male: Colour.Green800, female: Colour.Green300},
];
