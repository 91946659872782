import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Movie } from '../../../model/movie.model';
import { BasePageComponent } from '../../../modules/shared/components/base-page/base-page.component';
import { MovieService } from '../../../modules/shared/services/movie/movie.service';
import { DataLayerService } from '../../services/data-layer.service';

@Component({
  selector: 'app-base-card-movie',
  templateUrl: './base-card-movie.component.html',
  styleUrl: './base-card-movie.component.scss'
})
export class BaseCardMovieComponent extends BasePageComponent {
  @Input() movie: Movie = new Movie();
  @Input() openDetailOnclicked: boolean = true;
  @Input() containerName: string = '';
  @Output() onClicked: EventEmitter<any> = new EventEmitter<any>();

  protected movieService = inject(MovieService);
  protected dataLayerService = inject(DataLayerService);

  handleClickEvent(event: any) {
    this.dataLayerService.logMovieCardClicked(this.movie.id, this.containerName);
    if (this.openDetailOnclicked) {
      this.movieService.openDetail(this.movie.id);
    }
    this.onClicked.emit(event);
  }

}
