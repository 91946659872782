import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuId } from '../../../enums/menu-id.enum';

@Injectable({
  providedIn: 'root'
})
export class MenuNavService {

  activeMenuId$: BehaviorSubject<MenuId | null> = new BehaviorSubject<MenuId | null>(null);

  setActiveMenu(menuId: MenuId) { this.activeMenuId$.next(menuId);}
}
