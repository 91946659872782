import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAlphanumeric]',
})
export class AlphanumericDirective {
  @Input('appAlphanumeric') active: boolean | string = true;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    if (this.active !== '' && !this.active) {
      return;
    }

    const initalValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 -]/g, '');
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
