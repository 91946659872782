import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { validatePwdLength, validatePwdOneNo, validatePwdOneSpecial, validatePwdOneUpper } from '../../util/validator.util';

@Component({
  selector: 'app-password-tick-marks',
  templateUrl: './password-tick-marks.component.html',
  styleUrl: './password-tick-marks.component.scss'
})
export class PasswordTickMarksComponent {
  @Input() passwordControl!: FormControl;

  passwordReqs:any[] = [
    {
      textKey: 'MUST_BE_8',
      validator: validatePwdLength
    },
    {
      textKey: 'ONE_NO',
      validator: validatePwdOneNo
    },
    {
      textKey: 'ONE_UPPER',
      validator: validatePwdOneUpper
    },
    {
      textKey: 'ONE_SPECIAL',
      validator: validatePwdOneSpecial
    }
  ];

}
