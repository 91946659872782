@if (showTitle) {
<app-section-title [text]="label | translate" class="grow">
  <app-see-more-button [label]="label" (onClicked)="seeAllMoviesClicked.emit($event)"></app-see-more-button>
</app-section-title>
}

<div class="h-[max-content] w-full relative" [ngClass]="{'min-h-[342px]':isLoading}">
  @if (isLoading) {
  <app-loader-animation [isOverlay]="false"
    class="h-full !absolute top-0 right-0 bottom-0 left-0"></app-loader-animation>
  }
  <app-draggable-carousel [data]="movies" class="setMinHi">
    <ng-template let-movie>
      @if (isUpcomingMovie) {
      <app-card-movie-upcoming [containerName]="label" [showYear]="showYear" [hideDayDate]="hideDayDate" [movie]="movie"></app-card-movie-upcoming>
      } @else {
      <app-card-movie-synopsis [containerName]="label" class="px-[]" [movie]="movie"></app-card-movie-synopsis>
      }
    </ng-template>
  </app-draggable-carousel>
</div>
