import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { numberToRupiahCurrency } from '../util/helper';

@Pipe({
  name: 'rupiahCurrency'
})
export class RupiahCurrencyPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: string | number): any {
    return numberToRupiahCurrency(value);
  }

}
