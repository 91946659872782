<app-section-title text="COMMON.UPCOMING_MOVIES"></app-section-title>

<p-tabView>
  @for (tabData of tabs; track tabData.title) {
  <p-tabPanel [header]="tabData.title | translate">
    <div #scrollableElement [ngClass]="{'block w-full overflow-auto':isMobile}">
      <app-upcoming-movie-monthly-panel [data]="tabData"></app-upcoming-movie-monthly-panel>
    </div>
  </p-tabPanel>
  }
</p-tabView>
