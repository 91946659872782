<div class="breadcrumb-container cine-general-container-x h-[52px] w-full flex items-center">
  <a class="breadcrumb-item-separator inline cursor-pointer text-white text-sm font-medium mr-[5px]" routerLink="/home">
    <span><i class="pi pi-home"></i></span>
  </a>
  <div class="breadcrumb-item-container inline w-full text-white">
    @for (item of items; track $index) {
      <a class="breadcrumb-item cursor-pointer inline text-white text-sm font-medium" (click)="item.disabled? $event.stopPropagation() : navigate(item.url) ">
        {{item.label + '' | translate}}
      </a>
      @if ($index < items.length) {
        <span class="breadcrumb-item-separator inline"><i class="pi pi-angle-right"></i></span>
      }
    }
  </div>
</div>
