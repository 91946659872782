import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataLayerService } from '../../../../shared/services/data-layer.service';

@Component({
  selector: 'app-see-more-button',
  templateUrl: './see-more-button.component.html',
  styleUrl: './see-more-button.component.scss'
})
export class SeeMoreButtonComponent {
  @Input() label: string = '';
  @Output() onClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor(private dataLayerService: DataLayerService) {}
  logClickAnalytic() {
    this.dataLayerService.logSeeAllClicked(this.label);
  }

}
