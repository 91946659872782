import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
  items: MenuItem[] = [] as MenuItem[];
  home: MenuItem;

  constructor(private navSvc: NavigationService, private router: Router) {
    this.home = { icon: 'pi pi-home', fullUrl: '/home' };
    this.items = navSvc.buildBreadCrumb();
    this.navSvc.breadcrumbs.subscribe((val)=>{
      this.items = val;
      if (this.items.length > 0) this.items.at(-1)!.disabled = true;
    })
  }

  navigate(url:any) {
    this.router.navigate([url]);
  }
}
