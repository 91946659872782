export const TAB_BO_DAILY_LABEL = 'COMMON.DAILY_ADM';
export const TAB_BO_WEEKLY_LABEL = 'COMMON.WEEKLY_ADM';
export const TAB_BO_MONTHLY_LABEL = 'COMMON.MONTHLY_ADM';
export const TAB_BO_YEARLY_LABEL = 'COMMON.YEARLY_ADM';

export const TAB_BO_DAILY = 'DAILY';
export const TAB_BO_WEEKLY = 'WEEKLY';
export const TAB_BO_MONTHLY = 'MONTHLY';
export const TAB_BO_YEARLY = 'YEARLY';

export const TBO_TITLE_DAILY  = 'TOP_BOX_OFFICE.TITLE.DAILY';
export const TBO_TITLE_WEEKLY = 'TOP_BOX_OFFICE.TITLE.WEEKLY';
export const TBO_TITLE_MONTHLY = 'TOP_BOX_OFFICE.TITLE.MONTHLY';
export const TBO_TITLE_YEARLY = 'TOP_BOX_OFFICE.TITLE.YEARLY';
