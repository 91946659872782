<app-cine-table
      [data]="data.boxOffice"
      [totalRecords]="data.totalRecords"
      (onLazyLoad)="onLazyLoad.emit($event)"
      [loading]="data.isLoading"
      [currentPage]="data.currentPage"
      [pageSize]="data.pageSize"
    >
      <app-cine-table-column
        label="COMMON.RANK"
        property="rank.current_rank"
        [width]="75"
        [sort]="true"
      >
        <ng-template #cell let-rank="value">
          <span class="text-sm font-medium">{{ rank }}</span>
        </ng-template>
      </app-cine-table-column>

      <app-cine-table-column
        label="COMMON.TITLE"
        property="title"
        [width]="400"
        [sort]="true"
      >
        <ng-template #cell let-data>
          <app-card-movie-detail-horizontal
            containerName="COMMON.TOP_BOX_OFFICE"
            [showImage]="!isMobile"
            [showYear]="!isMobile"
            [showDuration]="!isMobile"
            [movie]="data"
          ></app-card-movie-detail-horizontal>
        </ng-template>
      </app-cine-table-column>

      <app-cine-table-column
        [label]="admissionLabel"
        property="admission"
        [dataType]="'number'"
        [width]="132"
        [sort]="true"
      ></app-cine-table-column>

      <app-cine-table-column
        label="COMMON.CHANGE"
        property="change"
        [width]="132"
        [sort]="true"
      >
        <ng-template #cell let-value="value">
          <app-percent-change-pill [value]="value"></app-percent-change-pill>
        </ng-template>
      </app-cine-table-column>

      <app-cine-table-column
        label="COMMON.TOTAL_ADM"
        [dataType]="'number'"
        property="total_admission"
        [width]="132"
        [sort]="true"
      ></app-cine-table-column>

      <app-cine-table-column
        label="COMMON.SHOWTIMES"
        [dataType]="'number'"
        property="showtimes"
        [width]="132"
        [sort]="true"
      ></app-cine-table-column>

      <app-cine-table-column
        label="COMMON.SCORE"
        property="score"
        [width]="132"
        [sort]="true"
      >
        <ng-template #cell let-value="value">
          <app-cine-flash-score [score]="value"></app-cine-flash-score>
        </ng-template>
      </app-cine-table-column>

      <app-cine-table-column label=""
        [width]="80"
        property="rank">
        <!-- Show double-up icon when rank goes up, double-down  -->
        <ng-template #cell let-data>
          @if (data.rank.current_rank === data.rank.last_rank) {
          <i class="icon-equals !bg-neutral-400 !w-[10px] !h-[10px]"></i>
          } @else {
          <i
            class="pi"
            [ngClass]="{
              'pi-angle-double-up text-primary-500':
                data.rank.current_rank < data.rank.last_rank,
              'pi-angle-double-down text-secondary-500':
                data.rank.current_rank > data.rank.last_rank
            }"
          ></i>
          }
        </ng-template>
      </app-cine-table-column>
    </app-cine-table>
