import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-versus-circle',
  templateUrl: './versus-circle.component.html',
  styleUrl: './versus-circle.component.scss'
})
export class VersusCircleComponent {
  @Input() orangeBg: boolean = true;
  @Input() styleClass: string = '';
}
