
export enum CountryType {
  All = 'ALL_COUNTRIES',
  Local = 'LOCAL',
  International = 'INTERNATIONAL',
}

export enum CountryTypeParam {
  All = 'all',
  Local = 'local',
  International = 'international'
}

