import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ScrollService } from './scroll.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  customIds: {[key: string]: any} = {};
  breadcrumbs: BehaviorSubject<Array<MenuItem>> = new BehaviorSubject<Array<MenuItem>>([] as MenuItem[]);

  lastState?: string;
  constructor(private route: ActivatedRoute, private router: Router, private scrollSvc: ScrollService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setCustom();
      }
    });
  }

  setBreadcrumbs(_bs: Array<MenuItem>) {
    this.breadcrumbs.next(_bs);
  }

  updateBreadcrumbs(_bs: Array<MenuItem>) {
    this.setBreadcrumbs(_bs);
  }

  buildBreadCrumb(): Array<any> {
    let route: ActivatedRoute | null = this.route;
    let url = '';
    const breadcrumbs: any[] = [];
    while(route) {
      const path = route.routeConfig ? route.routeConfig.path : '';
      let label = route.routeConfig?.data?.['title'] || '';
      const customId = route.routeConfig?.data?.['customId'] || '';
      const nextUrl = `${url}${path}/`;
      url = nextUrl;
      route = route.firstChild;
      if (customId && this.customIds[`${customId}`]) label = this.customIds[`${customId}`];
      if (label) breadcrumbs.push( { label, url } as MenuItem);
    }
    this.updateBreadcrumbs(breadcrumbs);
    setTimeout(() => {
      if (breadcrumbs[breadcrumbs.length-1]?.label !== this.lastState) {
        this.scrollSvc.scrollRoot();
        this.lastState = breadcrumbs[breadcrumbs.length-1]?.label;
      }
    }, 500);
    return breadcrumbs;
  }

  setCustom(key: string = '', value: string = '') {
    if (key) {
      this.customIds[`${key}`] = value;
    }
    this.buildBreadCrumb();
  }
}
