import { Component, Input } from '@angular/core';
import { MovieForComparison } from '../../../../model/movie.model';

@Component({
  selector: 'app-movie-compare-pair-box',
  templateUrl: './movie-compare-pair-box.component.html',
  styleUrl: './movie-compare-pair-box.component.scss'
})
export class MovieComparePairBoxComponent {
  @Input() movie1?: MovieForComparison;
  @Input() movie2?: MovieForComparison;
  @Input() compareLabel: string = '';

}
