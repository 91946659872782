import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface CineAlertStatic {label: string, desc: string, type: 'success'|'error'}
@Component({
  selector: 'app-cine-alert-static',
  templateUrl: './cine-alert-static.component.html',
  styleUrl: './cine-alert-static.component.scss'
})
export class CineAlertStaticComponent implements OnChanges {

  @Input() cineAlert: CineAlertStatic | null = {label: '', desc: '', type: 'success'};

  @Input() autoClose: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['cineAlert']) {
        if (this.autoClose) {
          setTimeout(() => {
            this.cineAlert = null;
          }, 3000);
        }
      }
  }
}
