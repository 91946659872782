import { Component, Input } from '@angular/core';
import { Movie } from '../../../../model/movie.model';

@Component({
  selector: 'app-movie-genres-text',
  templateUrl: './movie-genres-text.component.html',
  styleUrl: './movie-genres-text.component.scss'
})
export class MovieGenresTextComponent {
  @Input() movie?: Movie;

}
