import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { validateFormGroupForMathingPassword, validatorPwdDigit, validatorPwdLength, validatorPwdSpecialChar, validatorPwdUppercase } from '../../util/validator.util';

@Component({
  selector: 'app-password-fields',
  templateUrl: './password-fields.component.html',
  styleUrl: './password-fields.component.scss'
})
export class PasswordFieldsComponent implements OnChanges {
  @Input() form!: FormGroup;
  passwordIgnoreError = ['NODIGIT', 'PASSWORDLENGTH', 'NOSPECIAL', 'NOUPPERCASE'];

  get passwordCtrl(): FormControl { return this.form?.get('password') as FormControl; }
  get confirmPasswordCtrl(): FormControl { return this.form?.get('confirmPassword') as FormControl; }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      const form = changes['form'].currentValue as FormGroup;
      form.addControl('password', new FormControl('', [Validators.required, validatorPwdDigit, validatorPwdLength, validatorPwdSpecialChar, validatorPwdUppercase]));
      form.addControl('confirmPassword', new FormControl('', [Validators.required]));
      validateFormGroupForMathingPassword(form, 'password', 'confirmPassword');
    }
  }
}
