import { environment } from '../../environments/environment';
import { encryptSHA256 } from '../modules/shared/util/helper';

const api: string = `${environment.apiUrl}v1/`;

export const SessionKey = {
  USER_ID: encryptSHA256('cinepoint.userid'),
  USER: encryptSHA256('cinepoint.user'),
  UNSIGNED_USER: encryptSHA256('cinepoint.unsigned_user'),
  UNVERIFIED_USER: encryptSHA256('cinepoint.unverified_user'),
  REMEMBERED_USER: encryptSHA256('cinepoint.remembered_user'),
  CURRENT_LANG: encryptSHA256('cinepoint.language'),
  TOKEN: encryptSHA256('cinepoint.token'),
  REFRESH_TOKEN: encryptSHA256('cinepoint.refresh_token'),
  TIMER: encryptSHA256('cinepoint.timer'),
}
