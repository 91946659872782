@if (!videoId) {
  <div class="flex flex-row p-[24px] gap-[12px] bg-black w-full h-full justify-center items-center">
    <i class="pi pi-exclamation-circle w-[90px] h-[90px] text-[90px] text-white font-extrabold"></i>
    <span class="text-white text-[32px]/[48px]" translate="ERROR_MSG.CONTENTUNAVAIL"></span>
  </div>
} @else {
  <youtube-player
    [videoId]="videoId"
    [width]="width"
    [height]="height"
  ></youtube-player>
}
