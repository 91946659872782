@if (cineAlert) {
<div class="m-[24px] p-[16px] border rounded" [ngClass]="cineAlert.type==='success' ? 'bg-success-50 !border-[#1B8C17]':'bg-danger-50 !border-[#DF0A0A]'"
  style="transform: translateY(0px); opacity: 1;">
  <div class="flex gap-[16px] jusitfy-start items-start leading-none">
    <span>
      @switch (cineAlert.type) {
      @case ('error'){
      <i class="pi pi-exclamation-circle text-danger-500"></i>
      }
      @default {
      <i class="icon-check-circle text-success-500"></i>
      }
      }
    </span>
    <div class="flex flex-col gap-[8px]">
      <div class="font-bold">{{cineAlert.label}}</div>
      <div class="text-neutral-500">{{cineAlert.desc}}</div>
    </div>
    @if (!autoClose) {
    <button class="ml-auto" type="button" aria-label="Close">
      <i class="pi pi-times"></i>
    </button>
    }
  </div>
</div>
}