import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  scrollRoot() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToId(id: string): void {
    if (!id.startsWith('#')) id = '#'+id;
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
