<div (click)="handleClickEvent($event)" class="cursor-pointer !h-full flex flex-row gap-[16px] card-with-image">
  <!-- Movie Poster -->
  @if(showImage) {
  <div class="min-w-[72px] min-h-[88px] max-w-[72px] max-h-[88px]">
    <app-image-card
      [url]="movie.image_title!"
      [showFallbackText]="false"
      frameStyleClass="min-w-[72px] min-h-[88px] max-w-[72px] max-h-[88px]"
    ></app-image-card>
  </div>
  }

  <!-- Details to the Right of the Image -->
  <div class="flex flex-col text-description-xs gap-[4px] card-text-section">
    @if(movie.title) {
    <!-- Movie Title -->
    <div class="text-movie-title-lg truncate">{{ movie.title }}</div>

    <!-- Movie Genre -->
    <div>{{ getGenre() }}</div>

    <div class="flex" [ngClass]="isMobile? 'flex-row':'flex-col'">
      @if (showDuration) {
        <!-- Movie Duration -->
        <div>{{ getDuration() }}</div>
      }
      @if (showDuration && showYear && isMobile) {
        &nbsp;
        &#8226;
        &nbsp;
      }
      @if (showYear) {
        @if (hideDayDate) {
          <div>{{ getYear() | date: 'YYYY-MM'}}</div>
        } @else {
          <div>{{ getYear() }}</div>
        }
        <!-- Movie Year -->
      }
    </div>
    }
  </div>
</div>
