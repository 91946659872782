import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-movie-genre-pill',
  templateUrl: './movie-genre-pill.component.html',
  styleUrl: './movie-genre-pill.component.scss'
})
export class MovieGenrePillComponent {
  @Input() label: string = '';

}
