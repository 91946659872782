import { Component, Input } from '@angular/core';

export type BadgeType = 'success'|'info'|'warning'|'danger'; 
@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
})
export class BadgeComponent {
  @Input() label: string = '';
  @Input() type:BadgeType = 'info';
}
