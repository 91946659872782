import { Component } from '@angular/core';

@Component({
  selector: 'app-locked-feature',
  templateUrl: './locked-feature.component.html',
  styleUrl: './locked-feature.component.scss'
})
export class LockedFeatureComponent {

}
