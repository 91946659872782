export const MaxFileSizeInMb = 5;

export const FiveMB: number = MaxFileSizeInMb * 1024 * 1024;
export const SELECTION_ALL = 'All';

export const MEDIA_BREAKPOINT_MIN = {
  xs: 375, // Based on chrome Dimensions Iphone SE
  sm: 640, // Based on tailwind Docs
  md: 768, // Based on tailwind Docs
  lg: 1024 // Based on tailwind Docs
}

export const emptyAvatar = '../assets/images/avatar-empty.svg';