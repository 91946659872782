import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { zeroPad } from '../../util/helper';
import { BasePageComponent } from '../base-page/base-page.component';
@Component({
  selector: 'app-upcoming-movies',
  templateUrl: './upcoming-movies.component.html',
  styleUrl: './upcoming-movies.component.scss',
})
export class UpcomingMoviesComponent
  extends BasePageComponent
  implements OnInit
{
  tabs: any[] = [];

  getPeriods() {
    const today = new Date();
    const currentMonthStart = startOfMonth(today);
    const currentMonthEnd = endOfMonth(today);

    const nextMonthStart = startOfMonth(addMonths(today, 1));
    const nextMonthEnd = endOfMonth(addMonths(today, 1));

    const secondNextMonthStart = startOfMonth(addMonths(today, 2));
    const secondNextMonthEnd = endOfMonth(addMonths(today, 2));

    return [
      { start: currentMonthStart, end: currentMonthEnd },
      { start: nextMonthStart, end: nextMonthEnd },
      { start: secondNextMonthStart, end: secondNextMonthEnd },
    ];
  }

  ngOnInit(): void {
    this.tabs = this.getPeriods().map(p => {
      return {
        title: `FULL_MONTH.${p.start.getMonth()}`,
        period: `${p.start.getFullYear()}-${zeroPad( p.start.getMonth() + 1, 2 )}`,
      };
    })
  }
}
