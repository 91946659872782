import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-cast-crew-dialog',
  templateUrl: './cast-crew-dialog.component.html',
  styleUrl: './cast-crew-dialog.component.scss'
})
export class CastCrewDialogComponent implements OnInit {
  @Input() people: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.people = this.config.data['people'];
    setTimeout(() => {
      this.config.showHeader = false;
      this.config.dismissableMask = true;
    }, 0);

  }
}

