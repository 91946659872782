import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Movie } from '../../../../model/movie.model';
import { movieLabel } from '../../util/helper';
import { BasePageComponent } from '../base-page/base-page.component';

@Component({
  selector: 'app-movie-details-score-ratings',
  templateUrl: './movie-details-score-ratings.component.html',
  styleUrl: './movie-details-score-ratings.component.scss'
})
export class MovieDetailsScoreRatingsComponent extends BasePageComponent implements OnChanges {
  @Input() movies: Movie[] = [];
  @Input() verticalMode: boolean = false;
  @Input() showTitle: boolean = false;

  movieLabel = movieLabel;

  constructor(protected override route: ActivatedRoute ){
    super(route)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['movies'] && this.movies.length > 0) {
      this.movies.forEach(m => {
        m.user_ratings?.sort((a, b) => {return b.rating - a.rating});

      })
    }
  }
}
