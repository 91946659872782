import { TableLazyLoadEvent } from 'primeng/table';
import { IParamsTableData } from '../../../interface/common.interface';
import { clearObjectFromUndefinedProperties } from './helper';

export const getDefaultLazyLoadEvent = (): TableLazyLoadEvent => {
  return {
    first: 0,
    rows: 10,
  } as TableLazyLoadEvent;
};

export const convertLazyLoadEventToQueryParam = (event?: TableLazyLoadEvent): IParamsTableData => {

  let sortOrder, sortField;
  event ??= {};

  if (event.sortField) {
    sortField = Array.isArray(event.sortField)? event.sortField[0] : event.sortField;
    if (event.sortOrder && event.sortOrder < 0) sortOrder = 'desc';
    else if (event.sortOrder && event.sortOrder > 0) sortOrder = 'asc';
  }

  return  clearObjectFromUndefinedProperties({
    limit: event.rows ?? 10,
    order:  sortOrder,
    page: Math.floor( (event.first || 0) / (event.rows || 10) ),
    sort: sortField,
    // search: event.filters?.toString()
  });
}
