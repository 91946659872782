<div class="flex flex-col text-left">
  <app-field-label
    [isMandatory]="isMandatory"
    [labelKey]="labelKey"
  ></app-field-label>
  <div class="flex flex-row gap-[8px]">
    <p-inputGroup>
      <input
        [formControl]="inputControl"
        [readOnly]="feedback"
        type="text"
        appAlphanumeric
        class="grow {{inputStyleClass}}"
        pInputText
        [placeholder]="placeholderLabel | translate"
      />
      @if (feedback) {
        <p-inputGroupAddon class="right cursor-pointer" (click)="clearInput()">
          <i class="pi pi-times cursor-pointer" ></i>
        </p-inputGroupAddon>
      }
    </p-inputGroup>
    <app-cine-button
      buttonId="code-input_button-apply"
      [disabled]="inputEmpty || feedback"
      class="max-w-[140px]"
      styleClass="cine-button-primary {{buttonStyleClass}}"
      [label]="'COMMON.APPLY'"
      (onClick)="applyCode()"
    ></app-cine-button>
  </div>

  <div>
    @if (feedback) {
      <app-field-feedback
      [isError]="feedback.isError"
      [message]="feedback.message | translate: feedbackTranslationParams({duration: this.feedback.params?.duration, endDate: (feedback.params?.endDate|date) })"
    ></app-field-feedback>
    }
  </div>

</div>

