import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IListResponse } from '../../../../interface/common.interface';
import { Movie } from '../../../../model/movie.model';
import { MovieService } from '../../services/movie/movie.service';
import { BasePageComponent } from '../base-page/base-page.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-upcoming-movie-monthly-panel',
  templateUrl: './upcoming-movie-monthly-panel.component.html',
  styleUrl: './upcoming-movie-monthly-panel.component.scss',
})
export class UpcomingMovieMonthlyPanelComponent
  extends BasePageComponent
  implements OnInit
{

  @Input() data?: any;

  displayMovies: Movie[] = [];
  evenDisplayMovies: Movie[] = [];

  constructor(
    protected override route: ActivatedRoute,
    private movieService: MovieService
  ) {
    super(route);
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.addSubscription(
      this.movieService.getUpcomingMovies(this.data?.period, {}, true).pipe(finalize(()=>{this.isLoading = false})).subscribe({
        next: (res: IListResponse<Movie>['list']) => {
          this.displayMovies = res.content;
          this.evenDisplayMovies = [...this.displayMovies];
          if (this.evenDisplayMovies.length % 2 == 1) {
            this.evenDisplayMovies.push({
              id: -1,
            });
          }
        },
      })
    );
  }
}
