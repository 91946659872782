import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { Password } from 'primeng/password';
import { InputType } from '../../../../enums/input-type.enum';
import { formatDateISO8601 } from '../../util/date.util';

@Component({
  selector: 'app-input-control',
  templateUrl: './input-control.component.html',
  styleUrl: './input-control.component.scss'
})
export class InputControlComponent implements OnChanges {
  @Input() disabled: boolean = false;
  @Input() placeholder!: string;
  @Input() isMandatory: boolean = true;
  @Input() ngForm!: FormGroup;
  @Input() fieldName: string = '';
  @Input() fieldNameKey: string = '';
  @Input() errorFieldNameKey: string = '';
  @Input() controlType: string = '';
  @Input() excludeErrorKeys: string[] = [];
  @Input() inputStyleClass: string = '';
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() customErrors: any = {};
  @Input() toggleMask: boolean = true;
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dateInput') dateInputElement?: ElementRef<any>;
  @ViewChild(Calendar) cineCalendar?: Calendar;
  @ViewChild('textInput') textInput?: ElementRef<any>;
  @ViewChild('dateInput') dateInput?: ElementRef<any>;
  @ViewChild('passwordInput') passwordInput?: Password;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (changes['disabled']?.currentValue) {
        this.ctrl?.disable();
      } else {
        this.ctrl?.enable();
      }
    }
  }

  calendarDate?: any;
  updateDateValue() {
    this.ctrl.setValue(formatDateISO8601(this.calendarDate));
    this.closeCalendar();
    this.cdr.detectChanges();

  }
  calendarOverlayVisible: boolean = false;
  get ctrl(): FormControl {
    return this.ngForm.get(this.fieldName) as FormControl;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  isDefaultOrText(): boolean { return this.controlType === InputType.Default || this.controlType === InputType.Text; }

  closeCalendar(): void { this.calendarOverlayVisible = false; }
  toggleCalendar(event: Event): void {
    this.calendarDate = this.ctrl.value? new Date(this.ctrl.value) : this.defaultDate;
    this.calendarOverlayVisible = !this.calendarOverlayVisible;
  }

  get defaultDate(): Date {
    let date = new Date();
    date = (this.minDate && this.minDate > date)? this.minDate : date;
    date = (this.maxDate && this.maxDate < date)? this.maxDate : date;
    return date;
  }

  focus(): void {
    if (this.controlType === 'password') {
      this.passwordInput?.input.nativeElement.focus();
    } else if (this.controlType === 'date') {
      this.dateInput?.nativeElement.focus();
    } else {
      this.textInput?.nativeElement.focus();
    }
    this.cdr.detectChanges();
  }
}
