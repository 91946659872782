export const Params = {
  FREQ: 'freq',
  COUNTRY_TYPE: 'country',
  PERIOD: 'period',
  YEAR: 'year',
  GENRE: 'genre',
  DECADE: 'decade',
  RATING: 'rating',
  SORT: 'sort',
  ORDER: 'order',
  INDEX: 'initial',
  PAGE: 'page',
  MONTH: 'month',
  LIMIT: 'limit',
  SLUG: 'slug',
  CAST: 'cast'
}
