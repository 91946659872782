import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DemographicChartColours } from '../../../../enums/colour.enum';
import { ChartDataset, ChartMovieData } from '../../../../model/chart-data.model';
import { Movie } from '../../../../model/movie.model';
import { AuthService } from '../../services/auth/auth.service';
import { MovieService } from '../../services/movie/movie.service';
import { chartYaxistCurrencyDollar, chartYaxistSeparatorComa, isUserSubscribed } from '../../util/helper';
import { BasePageComponent } from '../base-page/base-page.component';
import { MovieDetailsIndividualChartComponent } from '../movie-details-individual-chart/movie-details-individual-chart.component';

@Component({
  selector: 'app-movie-details-charts',
  templateUrl: './movie-details-charts.component.html',
  styleUrl: './movie-details-charts.component.scss'
})
export class MovieDetailsChartsComponent extends BasePageComponent  {
  _movies: Movie[] = [];
  @Input()
  get movies(): Movie[] { return this._movies}
  set movies(movies: Movie[]) {
    this._movies = movies;
    this.initCharts();
  }
  @Input() showCompareLink: boolean = true;

  @ViewChild('admissionChart') admissionChart!: MovieDetailsIndividualChartComponent;
  @ViewChild('grossChart') grossChart!: MovieDetailsIndividualChartComponent;
  @ViewChild('showtimesChart') showtimesChart!: MovieDetailsIndividualChartComponent;
  @ViewChild('demographicChart') demographicChart!: MovieDetailsIndividualChartComponent;

  dataAdmission: any = {};
  dataGross: any = {};
  dataShowtimes: any = {};
  dataDemographic: any = {};

  isUserSubscribed: boolean = false;

  constructor(
    protected override route: ActivatedRoute,
    private authService: AuthService,
    private movieService: MovieService) {
      super(route);
      this.isUserSubscribed = isUserSubscribed( this.authService.getCurrentUser() );
    }

  initCharts(): void {
    this.initAdmissionChart();
    this.initGrossChart();
    this.initShowtimesChart();
    this.initDemographicChart();
  }

  initAdmissionChart(): void {
    this.initIndividualChart(this.movieService.getAdmissionChart.bind(this.movieService), this.admissionChart, this.dataAdmission);
  }

  initGrossChart(): void {
    this.initIndividualChart(this.movieService.getGrossChart.bind(this.movieService), this.grossChart, this.dataGross);
  }

  initShowtimesChart(): void {
    this.initIndividualChart(this.movieService.getShowtimesChart.bind(this.movieService), this.showtimesChart, this.dataShowtimes);
  }

  initDemographicChart(): void {
    this.initIndividualChart(this.movieService.getDemographicChart.bind(this.movieService), this.demographicChart, this.dataDemographic, true);
  }

  initIndividualChart(serviceCall: any, chartInstance: MovieDetailsIndividualChartComponent, dataObject: any, isDemographic: boolean = false): void {
    if (this.movies?.length === 0 || !chartInstance) {
      dataObject.labels = [];
      dataObject.datasets = [];
      return;
    }

    const obs = this.isUserSubscribed ?
    serviceCall(
      this.movies.map(m => m.id),
      chartInstance.countryType,
      chartInstance.periodType
    ) : (
      isDemographic? this.movieService.getFakeDemographicChartData() : this.movieService.getFakeChartData()
    );

    obs.subscribe({
      next: (dataList: ChartMovieData[]) => {
        if (!dataList || dataList.length === 0) return;
        dataObject.labels = this.extractLabelsFromData(dataList[0]);
        dataObject.datasets = this.extractDatasetsFromData(dataList, isDemographic);
      }
    });
  }

  extractLabelsFromData(data: ChartMovieData): string[] {
    return data.data.map( (x: any) => x.periode);
  }

  extractDatasetsFromData(list: any[], isDemographic: boolean = false): ChartDataset[] {
    return list.flatMap((item: any, index: number) => {
      return !isDemographic ? {
        label: item.title,
        data: item.data.map( (x: any) => x.value)
      } : [
        {
          label: `${item.title} - Male`,
          data:  item.data.map( (x: any) => x.male),
          stack: item.title,
          backgroundColor: DemographicChartColours[index].male
        },
        {
          label: `${item.title} - Female`,
          data: item.data.map( (x: any) => x.female),
          stack: item.title,
          backgroundColor: DemographicChartColours[index].female
        },
      ];
    })
  }

  chartYaxistCurrencyDollar = chartYaxistCurrencyDollar;

  chartYaxistSeparatorComa = chartYaxistSeparatorComa;

}
