<div class="flex flex-row items-center gap-[8px]">
  <i
  [ngClass]="{
    'bg-neutral-300': !ticked,
    'bg-success-400': ticked,
  }"
  class="pi pi-check bg-neutral-300 rounded-full w-[16px] h-[16px] text-[8px] text-white p-[4px]"></i>
  <span class="{{ticked? 'text-neutral-700' : 'text-neutral-400'}}" [translate]="textKey"></span>

</div>
