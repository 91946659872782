import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrl: './code-input.component.scss'
})
export class CodeInputComponent {
  @Input() labelKey: string = '';
  @Input() isMandatory: boolean = false;
  @Input() inputStyleClass: string = '';
  @Input() buttonStyleClass: string = '';
  @Input() inputControl!: FormControl;
  @Input() placeholderLabel: string = '';
  @Input() feedback: any;
  @Output() onApplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  get inputEmpty(): boolean {return !this.inputControl || this.inputControl?.value.trim() === ''}

  clearInput(): void {
    this.inputControl?.setValue('');
    this.onClear.emit();
  }
  applyCode(): void {
    this.onApplied.emit(this.inputControl?.value);
  }

  feedbackTranslationParams(directParam: Object): Object {
    return { 
      ...this.feedback.params, // additional indirect param
      ...directParam
    }
  }

}
