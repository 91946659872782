<div
  class="rounded-full relative flex w-[80px] h-[80px]
  font-semibold text-[32px]/[40px]
  justify-center items-center
  {{ orangeBg? 'text-white bg-[#FDA410]' : 'text-[#868F9E] bg-[#F3F3F3]' }}
  {{styleClass}}
  "
>
  VS
</div>
