import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PlanType } from '../../../../enums/plan-type.enum';
import { Plan } from '../../../../model/plan.model';

@Component({
  selector: 'app-card-plan',
  templateUrl: './card-plan.component.html',
  styleUrl: './card-plan.component.scss'
})
export class CardPlanComponent {
  @Input() plan!: Plan;
  @Input() current: boolean = false;
  get disabled(): boolean { return this.current }

  get borderClass(): string {
    if (this.selected) return 'border-[3px] border-primary-500';
    return 'border-neutral-200';
  }

  get planTitle():string {
    return `COMMON.${this.plan.type.toUpperCase()}_PLAN`;
  }

  get price():number {
    return this.plan.price;
  }

  get description():string {
    return this.plan.description;
  }

  get inputId():string {
    return `radio${this.plan.type}`;
  }

  get value():string {
    return this.plan.id;
  }

  get icon(): string {
    return this.plan.type === PlanType.Monthly ? 'flash' : 'star';
  }

  @Input() form!: FormGroup;
  @Input() controlName: string = '';

  get selectedPlan(): string {
    return this.form.get(this.controlName)?.value;
  }

  set selectedPlan(v: string) {
    this.form.get(this.controlName)?.setValue(v);
  }

  get selected(): boolean {
    return this.selectedPlan === this.plan.id;
  }

  get recommended(): boolean {
    return this.plan.type === PlanType.Yearly;
  }

}
