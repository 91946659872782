import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-page',
  templateUrl: './card-page.component.html',
  styleUrl: './card-page.component.scss'
})
export class CardPageComponent {
  @Input() title:string = ''; 
  @Input() subTitle:string = ''; 
  @Input() useBackBtn: boolean = false;
  @Output() back: EventEmitter<unknown> = new EventEmitter<unknown>();
}
