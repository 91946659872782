<div class="fgroup">
  <app-field-label
    [isMandatory]="isRequired"
    [labelKey]="labelKey"
  ></app-field-label>
  <div class="fphone">
    <p-dropdown
      (onFocus)="onFocus.emit($event)"
      [options]="countryCodeList"
      [formControl]="codeCtrl!"
      [styleClass]="
        (phoneCtrl?.touched && phoneCtrl?.invalid
          ? 'ng-invalid ng-touched'
          : '') + ' ' + dropdownStyleClass
      "
    ></p-dropdown>
    <span class="p-input-icon-right w-full">
      <input
        #phoneNumberInput
        type="text"
        pInputText
        (focus)="onFocus.emit($event)"
        class="!pr-[30px] w-full {{ disabled? 'cine-disabled' : ''}} {{inputStyleClass}}"
        [appOnlyNumber]="true"
        [formControl]="phoneCtrl!"
        [placeholder]="'SIGN_UP.PLACEHOLDER_PHONE' | translate"
      />
      <i
        class="cine-error pi pi-exclamation-circle text-danger-400 w-[13px] h-[13px]"
      ></i>
      <ng-content select="[rightSideIcon]"></ng-content>

    </span>

  </div>
  <app-field-errors
    [form]="ngForm"
    [fieldName]="phoneNoFieldName"
    [fieldNameKey]="'COMMON.PHONE_NO'"
  ></app-field-errors>
</div>
