export const extractYouTubeVideoId = (url: string): string => {
  let videoId: string | null | undefined = '';
  try {
      const parsedUrl = new URL(url);
      if (['www.youtube.com', 'youtube.com', 'youtu.be'].includes(parsedUrl.hostname)) {
          if (parsedUrl.pathname === '/watch') {
              const searchParams = new URLSearchParams(parsedUrl.search);
              videoId = searchParams.get('v');
          } else if (parsedUrl.pathname.startsWith('/embed/')) {
              videoId = parsedUrl.pathname.split('/').pop();
          } else if (parsedUrl.pathname.startsWith('/v/')) {
              videoId = parsedUrl.pathname.split('/').pop();
          } else if (parsedUrl.hostname === 'youtu.be') {
              videoId = parsedUrl.pathname.substring(1); // Remove leading '/'
          }
      }
  } catch (error) {
      console.error('Error parsing YouTube URL:', error);
  }
  return videoId ?? '';
}
