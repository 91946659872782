import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { delay, from, switchMap } from 'rxjs';
import { imageCacheBuster } from '../../util/helper';

export type ImageType = 'movie_poster' | 'profile' | 'cast' | 'other';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrl: './image-card.component.scss'
})
export class ImageCardComponent implements OnChanges {
  @Input() rounded: boolean = true;
  _url: string = '';
  @Input()
  get url(): string { return this._url}
  set url(u: string) {
    this._url = this.noCache ? imageCacheBuster(u) : u;
    this.usingFallbackImg = false;
  }
  @Input() useFrameBoard: boolean = false;
  @Input() imageStyleClass: string = '';
  @Input() frameStyleClass: string = '';
  @Input() fallbackImageType: ImageType = 'other';
  @Input() alt: string = '';
  @Input() imageErrorMsg: string = 'COMMON.MOVIE_POSTER_UNAVAIL';
  imgNgClass: any = {};
  usingFallbackImg: boolean = false;
  @Input() showFallbackText: boolean = true;
  retryCount: number = 3;
  @Input() noCache: boolean = true;

  get fallbackImage(): string {
    let filename;
    switch (this.fallbackImageType) {
      case 'movie_poster':
        filename = 'icon-movie-404.svg'
        break;
      case 'profile': 
        filename = 'avatar-empty.svg' 
        break;
      case 'cast':
        filename = 'icon-movie-404.svg'
        break;
      default:
        filename = 'icon-movie-404.svg'
        break;
    }
    return `../../../../../assets/images/${filename}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['url']) {
        this._url = this.noCache ? imageCacheBuster(this.url) : this.url;
      }
  }

  retryReloading(): void {
    from([this.url]).pipe(
      delay(100),
      switchMap((url: string, index: number) => {
        this.url = this.noCache ? imageCacheBuster(url) : url;
        if (this.retryCount > 0) this.retryCount -= 1;
        return url;
      })
    ).subscribe();
  }

  useFallbackImage(): void {
    if (this.url != null && this.url !== '' && this.retryCount < 3) {
      this.retryReloading();
      return;
    }
    this.usingFallbackImg = true;
    this._url = this.fallbackImage;
  }

}
