import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Endpoint } from '../../../../constants/endpoint.constant';
import { IListResponse } from '../../../../interface/common.interface';
import { Country } from '../../../../model/country.model';
import { BaseService } from '../../../../services/base.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService{

  countries$: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);

  constructor(override http: HttpClient) {
    super(http);
  }

  getCountryList(): Observable<IListResponse<Country>['list']> {
    return this.getList<Country>(Endpoint.COUNTRIES);
  }

  initializeCountryListCache(): void {
    this.getCountryList().subscribe({
      next: (list: IListResponse<Country>['list']) => {
        this.countries$.next(list.content);
      }
    })
  }
}
