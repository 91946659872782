import { Component, ContentChild, Input, TemplateRef } from '@angular/core';

export interface ICineTableColumn {
  label: string,
  property: string,
  sort: boolean,
  width?: number,
  dataType: 'string' | 'number' | 'date',
  transformValue?: (val: any) => any,
}

@Component({
  selector: 'app-cine-table-column',
  templateUrl: './cine-table-column.component.html',
  styleUrl: './cine-table-column.component.scss'
})
export class CineTableColumnComponent {
  @Input() label: string = '';
  @Input() property: string = '';
  @Input() width: number = 80;
  @Input() sort: boolean = false;
  @Input() transformValue?: (val: any) => any;
  @Input() dataType: 'string' | 'number' | 'date' = 'string';

  @ContentChild('header') headerTemplate?: TemplateRef<any>;
  @ContentChild('cell') cellTemplate?: TemplateRef<any>;

  _dbField: string = '';
  @Input()
  get dbField(): string { return this._dbField || this.property }
  set dbField(f: string) { this._dbField = f }
}
