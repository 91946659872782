import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tick-mark',
  templateUrl: './tick-mark.component.html',
  styleUrl: './tick-mark.component.scss'
})
export class TickMarkComponent {
  @Input() ticked: boolean = false;
  @Input() textKey: string = '';
}
