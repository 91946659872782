<div class="flex flex-col gap-[24px]">
  <!-- Title -->
  <span translate="COMMON.CAST_N_CREW" class="text-[20px]/[32px] font-semibold text-neutral-700"></span>

  <!-- Scrollable area for the content -->
  <div class="flex flex-col min-h-[420px] max-h-[420px] overflow-auto gap-[20px]">
    @for (group of people; track $index) {
      @if(group.list?.length > 0) {
        <div class="flex flex-col gap-[8px]">
          <span [translate]="group.title" class="text-sm font-semibold text-neutral-900"></span>
          <div class="flex flex-wrap gap-y-[8px]">
            @for (person of group.list; track $index) {
              <span class="w-1/2 text-neutral-400 text-sm">{{person.name}}</span>
            }
          </div>
        </div>
      }

    }
  </div>




</div>

