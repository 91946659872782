import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Colour } from '../../../../enums/colour.enum';
import { CountryType } from '../../../../enums/country-type.enum';
import { PeriodType } from '../../../../enums/period-type';
import { createCountryTypeOptions, createPeriodTypeOptions } from '../../util/helper';
import { BasePageComponent } from '../base-page/base-page.component';

@Component({
  selector: 'app-movie-details-individual-chart',
  templateUrl: './movie-details-individual-chart.component.html',
  styleUrl: './movie-details-individual-chart.component.scss'
})
export class MovieDetailsIndividualChartComponent extends BasePageComponent implements OnInit, OnChanges {

  @Input() override title: string = '';
  @Input() desc: string = '';
  @Input() xLabels: any;
  @Input() datasets: any[] = [];
  @Input() showFilters: boolean = true;
  @Input() chartType: string = 'line';
  @Input() xAxisTickCallback?: (value: any, index: any, ticks: any) => string;
  @Input() yAxisTickCallback?: (value: any, index: any, ticks: any) => string;
  @Input() tooltipCallbacks?: any;
  @Input() beginAtZero: boolean = true;
  @Input() isLocked: boolean = false;
  allZero: boolean = false;

  colours: string[] = Object.values(Colour);
  data: any;
  options: any;
  periodType: string = PeriodType.Weekly;
  countryType: CountryType = CountryType.All;

  periodTypes = createPeriodTypeOptions().filter(item=>item.value !== PeriodType.Daily);
  countryTypes = createCountryTypeOptions();
  @Output() countryTypeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() periodTypeChanged: EventEmitter<any> = new EventEmitter<any>();

  onCountryTypeChanged(event: DropdownChangeEvent): void {
    this.countryTypeChanged.emit(event.value);
  }
  onPeriodTypeChanged(event: DropdownChangeEvent): void {
    this.periodTypeChanged.emit(event.value);
  }

  textColor: string = '#212529';
  textColorSecondary: string = '#6c757d';
  surfaceBorder: string = '#dee2e6';


  allChangesComplete(changes: SimpleChanges): boolean {
    return this.datasets && this.xLabels;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.allChangesComplete(changes)) {
      this.initializeChart();
    }
  }

  ngOnInit(): void {
    this.initializeChart();
  }

  initializeChart(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    this.textColor = documentStyle.getPropertyValue('--text-color');

    this.textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    this.allZero = !this.datasets.find(set=>set.data.find((item:number)=>item>0));
    this.surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.data = {
      labels: this.xLabels,
      datasets: this.datasets?.map((set: any, index: number) => {
        return {
          fill: false,
          type: this.chartType,
          backgroundColor: this.colours[index],
          borderColor: this.colours[index],
          ...set,
        }
      }) ?? []
    };

    this.options = {
      type: this.chartType,
      maintainAspectRatio: false,
      aspectRatio: this.isMobile ? 1.0:0.6,

      plugins: {
        legend: {
          display: false,
          labels: {
            color: this.textColor,
          },
        },
        ...{
          tooltip: this.tooltipCallbacks? {
            callbacks: this.tooltipCallbacks
          } : undefined
        }
      },
      scales: {
        x: {
          stacked: this.chartType === 'bar',
          ticks: this.xAxisTickCallback? {
            color: this.textColorSecondary,
            callback: this.xAxisTickCallback,
          } : {
            color: this.textColorSecondary,
          },
          grid: {
            display: false,
            color: this.surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          stacked: this.chartType === 'bar',
          ticks: {
            color: this.textColorSecondary,
            callback: this.yAxisTickCallback,
          },
          grid: {
            color: this.surfaceBorder,
            drawBorder: false,
          },
          ...(this.beginAtZero && {
            beginAtZero: this.beginAtZero,
            min: 0
          })
        },
      },
    };
  }
}
