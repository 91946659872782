<app-rounded-card
  (click)="!disabled && selectedPlan = value"
  [showShadowbox]="true"
  [active]="selected"
  [width]="328"
  [height]="256"
  [gap]="24"
  [additionalClass]="borderClass + ' ' + (disabled? '!bg-gray-50 ' : 'cursor-pointer ')"
>
  @if (recommended || current) {
  <div class="plan-top">
    <div
      class="top-pill {{ disabled ? '!bg-gray-400' : ( current ? '!bg-primary-500' : '' ) }}"
      [translate]="current ? 'COMMON.CURRENT_PLAN' : 'COMMON.RECOMMENDED'"
    ></div>
  </div>
  }
  <img
    src="../../../../../assets/icons/icon-plan-{{ icon }}.svg"
    class="w-[40px] h-[40px]"
    alt=""
  />

  <div class="flex flex-row justify-between">
    <span
      class="font-semibold text-[18px]/[28px] text-neutral-600"
      [translate]="planTitle"
    ></span>
    @if (recommended) {
    <p-tag [rounded]="true" class="popular-pill">{{
      "COMMON.POPULAR" | translate
    }}</p-tag>
    }
  </div>

  <div class="flex flex-row items-center">
    <span class="text-[36px]/[48px] font-semibold text-neutral-900">Rp</span>
    <span class="text-[48px]/[64px] font-semibold text-neutral-900">{{
      price | number : "1.0" : "id"
    }},-</span>
    <span class="text-[16px]/[24px] font-normal text-neutral-600"
      >/{{ "COMMON.MONTH" | translate | lowercase }}</span
    >
  </div>

  <div class="mt-[-20px] flex flex-row justify-between">
    <span class="text-sm text-neutral-600">{{ description }}</span>
    <p-radioButton
      #radioBtn
      class="cine-radiobutton"
      name="plan"
      [value]="value"
      [(ngModel)]="selectedPlan"
      [inputId]="inputId"
      [disabled]="disabled"
    ></p-radioButton>
  </div>
</app-rounded-card>
