import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-filter-options-popup',
  templateUrl: './mobile-filter-options-popup.component.html',
  styleUrl: './mobile-filter-options-popup.component.scss'
})
export class MobileFilterOptionsPopupComponent {
  @Input() header: string = '';
  @Input() subheader: string = '';
  @Input() options: {label: string, value: string}[] = [];
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input({ required: true }) value: string | number = '';
  @Output() valueChange = new EventEmitter<string | number>();

  onValueChange(newValue: string | number) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }

  @Output() onSave = new EventEmitter<string | number>();
}
