import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-filter-entry',
  templateUrl: './mobile-filter-entry.component.html',
  styleUrl: './mobile-filter-entry.component.scss'
})
export class MobileFilterEntryComponent {
  @Input() title: string = '';
  @Input() value: string = '';
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
