<div class="{{ isError? 'invalid-feedback' : 'success-feedback' }}">
  @for (err of getErrors(); track $index) {
    <div class="flex flex-row gap-[4px]">
      @if (showIcon) {
        <i [class]="iconClass"></i>
      }
      {{ err.errorKey | translate: err.params }}
    </div>
  }
</div>
