<div class="pb-[16px]">
<div class="flex gap-[16px] flex-col md:flex-row lg:items-center">
  <span
    class="text-[20px]/[28px] flex items-center font-semibold"
    [translate]="title"
  ></span>

  <!-- Legend, appears when there are more than one dataset -->
  <div class="grow flex gap-[16px] flex-wrap lg:flex-nowrap">
    @if (data.datasets && data.datasets.length > 1) {
      @for (item of data.datasets; track $index) {
        <span class="flex gap-[8px] items-center">
          <div class="dot" [ngStyle]="{
              'background-color': item.backgroundColor
            }"></div>

          <span class="text-xs" [translate]="item.label"></span>
        </span>
      }
    }
  </div>

  @if (showFilters) {
    <!-- All/local/international -->
    <div class="flex gap-[8px] mb-[16px]">
      <p-dropdown
        [(ngModel)]="countryType"
        styleClass="!w-[150px]"
        optionLabel="label"
        optionValue="value"
        [options]="countryTypes"
        (onChange)="onCountryTypeChanged($event)">
        <ng-template pTemplate="item" let-item>
          <ng-container
            *ngTemplateOutlet="dropdownItem; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="selectedItem" let-item>
          <ng-container
            *ngTemplateOutlet="dropdownItem; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="dropdownicon"></ng-template>
      </p-dropdown>

      <!-- Weekly/monthly/yearly -->
      <p-dropdown styleClass="!w-[132px]"
        [(ngModel)]="periodType"
        optionLabel="label"
        optionValue="value"
        [options]="periodTypes"
        (onChange)="onPeriodTypeChanged($event)">
        <ng-template pTemplate="item" let-item>
          <ng-container
            *ngTemplateOutlet="dropdownItem; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="selectedItem" let-item>
          <ng-container
            *ngTemplateOutlet="dropdownItem; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
        <ng-template pTemplate="dropdownicon"></ng-template>
      </p-dropdown>
    </div>
  }

</div>
<div>{{desc|translate}}</div>
</div>

@if (xLabels?.length > 0 && datasets && datasets.length > 0) {
  <div class="chart-container relative">
    @if (allZero) {
      <div class="absolute w-full h-full top-0 left-0 right-0 bottom-0 z-[1] flex items-center justify-center">
        {{'COMMON.NO_RECORDS_FOUND'|translate}}
      </div>
    }
    <p-chart
      class=" {{isLocked ? 'blur-[7px]' : ''}}"
      [type]="chartType"
      [data]="data"
      [options]="options"
      height="500"
    ></p-chart>

    @if (isLocked) {
      <div class="absolute top-0 w-full h-full">
        <app-locked-feature></app-locked-feature>
      </div>
    }
  </div>
} @else {
  <div class="w-full h-full min-h-[280px] flex items-center justify-center">
    {{'COMMON.NO_RECORDS_FOUND'|translate}}
  </div>
}

<ng-template #dropdownItem let-item pTemplate="item">
  <div class="flex align-items-center gap-2">
    <div class="text-sm overflow-hidden text-ellipsis">{{ item.label | translate }}</div>
  </div>
</ng-template>
