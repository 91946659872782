<div class="section-title {{extraClass}}">
  <span [translate]="text"
    class="text-neutral-900
      {{ smallMode ?
        'text-[24px]/[32px]' :
        'text-[20px]/[32px] lg:text-[32px]/[48px]'
      }}
      font-semibold grow"></span>
  <ng-content></ng-content>
</div>
