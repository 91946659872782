<app-rounded-card [showShadowbox]="true"
  additionalClass="!p-[16px]"
>
  <!-- Title -->
  <div class="h-[56px] text-[#35B2D3] text-[18px]/[28px] font-medium !text-center">{{movie1?.title}} vs {{movie2?.title}}</div>

  <!-- Image posters & text -->
  <div class="flex">
    <ng-container *ngTemplateOutlet="movieTemplate; context: { $implicit: movie1 }"></ng-container>
    <div class="flex flex-row-reverse">
      <ng-container *ngTemplateOutlet="movieTemplate; context: { $implicit: movie2 }"></ng-container>
      <app-versus-circle class="mt-[94px] mx-[-30px]"></app-versus-circle>
    </div>
  </div>

</app-rounded-card>

<ng-template #movieTemplate let-movie>
  <div class="flex flex-col">
    <app-image-card [frameStyleClass]="'!w-[215px] !h-[268px]'" [url]="movie?.image_title ?? ''"></app-image-card>
    <span class="mt-[15px] text-base font-semibold" [translate]="compareLabel"></span>
    <span class="text-sm text-[#667085]">{{ 'COMMON.ADMISSION' | translate }} - {{movie?.admission}}</span>
    <span class="text-sm text-[#667085]">{{ 'COMMON.GROSS' | translate }} - {{movie?.gross | dollarCurrency}}</span>
  </div>
</ng-template>
