import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
  @Input('appOnlyNumber') active: boolean | string = true;
  @Input() useDecimal: boolean = false;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    if (this.active !== '' && !this.active) {
      return;
    }
    const initalValue = this.el.nativeElement.value;

    if (this.useDecimal) {
      this.el.nativeElement.value = initalValue.replace(/[^0-9.]|\.(?=.*\.)/g, '');
    } else {
      this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    }
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
  @HostListener('blur', ['$event.target.value']) onBlurChange(value: string) {
    // Cast the event target to HTMLInputElement
    if (!value) {
      return; // If there is no value, we don't need to do anything.
    }
    let initalValue = value;
    // Check for decimal and trailing dots
    if (this.useDecimal) {
      // Replace trailing dot with an empty string
      initalValue = initalValue.replace(/\.$/, '');
      // Prepend '0' if the string starts with a dot
      if (initalValue.startsWith('.')) {
        initalValue = '0' + initalValue;
      }
      this.el.nativeElement.value = initalValue;
    }
  }
}
