<div
  class="gap-[8px] {{rounded? 'rounded-lg' : '' }} overflow-hidden flex flex-col object-cover items-center justify-center"
  [ngClass]="frameStyleClass ? frameStyleClass : 'w-[inherit] h-[inherit]'"
  [class.frame]="useFrameBoard"
  [class.bg-neutral-200]="usingFallbackImg"
>
  <img
    [src]="url"
    (error)="useFallbackImage()"
    [alt]="alt"
    class="{{rounded? 'rounded' : ''}} overflow-hidden w-full h-full object-cover {{
      imageStyleClass
    }}"
    [class.image-fallback]="usingFallbackImg && fallbackImageType !== 'profile'"
    [class.image-fallback-small]="usingFallbackImg && !showFallbackText && fallbackImageType !== 'profile'"
    [ngClass]="imgNgClass"
  />

  @if (usingFallbackImg && showFallbackText) {
    <span class="whitespace-pre-line text-sm text-neutral-400 text-center" [translate]="imageErrorMsg"></span>
  }
</div>
