import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-feedback',
  templateUrl: './field-feedback.component.html',
  styleUrl: './field-feedback.component.scss'
})
export class FieldFeedbackComponent {
  @Input() isError: boolean = true;
  @Input() message: string = '';
}
