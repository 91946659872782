import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';

@Component({
  selector: 'app-box-office-table',
  templateUrl: './box-office-table.component.html',
  styleUrl: './box-office-table.component.scss'
})
export class BoxOfficeTableComponent extends BasePageComponent {
  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: any = {};
  @Input() admissionLabel: string = '';
}
