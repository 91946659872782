<p-card>
  <div class="flex flex-col gap-[16px] border-b pb-[24px] lg:p-[24px]">
    <h2>{{ title | translate }}</h2>
    <p class="m-0 text-gray-500">
      {{ subTitle | translate }}
    </p>
  </div>
  <div class="pt-[24px] lg:pt-[32px] lg:p-[24px]">
    @if (useBackBtn) {
    <app-cine-button
      buttonId="card-page_button-back"
      [label]="'COMMON.BACK' | translate"
      (onClick)="back.emit()"
      styleClass="carousel-nav-button !border-0 !w-[max-content] !px-0 !pt-0 !pb-[24px]"
      icon="pi pi-angle-left"
    ></app-cine-button>
    }
    <ng-content></ng-content>
  </div>
</p-card>
