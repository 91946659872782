<div class="mt-[30px] lg:mt-[60px] flex flex-col gap-[24px]">
  <!-- Title -->
  <div class="flex flex-row gap-[8px] items-center">
    <span translate="COMMON.SCORE" class="text-[20px]/[28px] text-neutral-900 font-semibold"></span>
    <span class="text-[16px]/[16px] text-neutral-900 font-[300]">Cinepoint</span>
    <span class="label-flash text-primary-500 text-xs italic font-bold">Flash
      @for (i of [].constructor(2); track $index) {
        <i class="icon-flash bg-primary-500 !w-[12px] !h-[12px]"></i>
      }
    </span>
  </div>
  @if (movies.length <1) {
    <div class="w-full h-full min-h-[280px] flex items-center justify-center">
      {{'COMMON.NO_RECORDS_FOUND'|translate}}
    </div>
  }
  <div [ngClass]="{'flex flex-row overflow-auto':movies.length>0}" class="gap-[16px] lg:gap-[32px]">
    @for (movie of movies; track $index) {
      <!-- Score and Rating -->
      <!-- look @ other.constants.ts/ MEDIA_BREAKPOINT_MIN.xs -32px -->
      <div class="flex {{ verticalMode? 'flex-col items-center min-w-[343px]' : 'flex-row' }} flex-1 justify-center gap-[12px] lg:gap-[24px]">

        <!-- Movie Title -->
        @if (showTitle) {
          <div class="text-neutral-900 text-base font-semibold text-center">{{movieLabel(movie)}}</div>
        }

        <!-- Score box -->
        <app-score-box [orangeBg]="$index === 0" [score]="movie.score ?? 0"></app-score-box>

        <!-- Rating progress bars -->
        <div class="flex flex-col gap-[8px] w-full">
          @for (rating of movie.user_ratings; track $index) {
            <div class="flex flex-row gap-[12px] items-center">
              <span class="text-right text-[#667085] w-[2em]">{{rating.rating}}</span>
              <p-progressBar class="grow" [value]="rating.value" [showValue]="false" color="#FDA410" styleClass="h-[8px]"></p-progressBar>
              <span class="text-right text-[#667085] w-[3em]">{{rating.value}}%</span>
            </div>
          }
        </div>
      </div>
    }
  </div>


</div>
