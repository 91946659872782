import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-save-confirm-dialog',
  templateUrl: './save-confirm-dialog.component.html',
  styleUrl: './save-confirm-dialog.component.scss'
})
export class SaveConfirmDialogComponent {

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  cancel() {
    this.ref.close(false);
  }

  continue() {
    this.ref.close(true);
  }
}
