<div class="relative">
<div id="cine_table_top" class="absolute top-[-11rem] lg:top-[-10rem] right-0 left-0"></div>
@if (loading && !hideLoaderAnim) {
  <div class="absolute top-0 left-0 right-0 bottom-0">
    <app-loader-animation [isOverlay]="false" class="h-full !absolute top-0 right-0 bottom-0 left-0"></app-loader-animation>
  </div>
}
<p-table
  class="cine-table"
  [value]="data"
  [lazy]="lazy"
  (onLazyLoad)="lazyLoad($event)"
  dataKey="id"
  [selection]="selectedData"
  [selectAll]="selectAll"
  paginatorStyleClass="cine-paginator"
  [paginator]="true"
  [first]="firstRecord"
  [showFirstLastIcon]="false"
  [pageLinks]="10"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [rows]="pageSize || 10"
  [totalRecords]="totalRecords"
  [globalFilterFields]="[
    'name',
    'country.name',
    'company',
    'representative.name'
  ]"
>
  <ng-template pTemplate="header">
    <!-- Row containing column headers -->
    <tr>
      @if (selectable) {
        <th style="width: 4rem"></th>
      }

      @for (column of columns; track column.label) {
        <th
          [pSortableColumnDisabled]="!column.sort"
          [pSortableColumn]="column.dbField"
          [width]="column.width"
          [ngStyle]="{'max-width.px': column.width, 'min-width.px': column.width}"
          class="whitespace-nowrap"
          >
          @if (column.headerTemplate) {
            <ng-container
              [ngTemplateOutlet]="column.headerTemplate"
              [ngTemplateOutletContext]="{
                $implicit: column.label
              }"
            ></ng-container>
          } @else {
            {{column.label | translate}}
          }

          @if (column.sort) {
            <p-sortIcon [field]="column.property"> </p-sortIcon>
          }
        </th>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-data>
    <tr>
      @if (selectable) {
        <td>
          <p-tableCheckbox [value]="data"></p-tableCheckbox>
        </td>
      }

      @for (column of columns; track column.label) {
        <td
          [ngStyle]="{'max-width.px': column.width, 'min-width.px': column.width}"
          [width]="column.width">
          @if (column.cellTemplate) {
            <ng-container
              [ngTemplateOutlet]="column.cellTemplate"
              [ngTemplateOutletContext]="{
                $implicit: data,
                value: getValue(data, column)
              }"
            ></ng-container>
          } @else {
            {{
              getValue(data, column)
                | dynamicPipe: (isNumberColumn(column)? numberPipe : null): [ '1.0', 'id' ]
            }}
          }
        </td>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <div class="flex flex-row text-neutral-700 text-sm font-medium gap-[12px] items-center">
      <p-dropdown [options]="pageSizeOptions"
        appendTo="body"
        [(ngModel)]="pageSize" (ngModelChange)="pageSizeChanged()"></p-dropdown>
      <span translate="COMMON.ROWS_PER_PAGE"></span>
    </div>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr class="!bg-white h-[500px]">
      <td class="!bg-white text-center" colspan="200">
        @if (!loading && !initializing) {
          {{'COMMON.NO_RECORDS_FOUND'|translate}}
        }
      </td>
    </tr>
  </ng-template>
</p-table>
</div>
