import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cine-button',
  templateUrl: './cine-button.component.html',
  styleUrl: './cine-button.component.scss'
})
export class CineButtonComponent {

  @Input({ required: true }) buttonId: string = '';
  @Input() label: string = '';
  @Input() severity: any;
  @Input() styleClass: string = '';
  @Input() icon: string = '';
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  get class(): string {
    return `${this.styleClass}`;
  }

  handleClick(event: Event) {
    this.onClick.emit(event);
  }
}
