import { AfterContentChecked, Component, ContentChild, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-draggable-carousel',
  templateUrl: './draggable-carousel.component.html',
  styleUrl: './draggable-carousel.component.scss'
})
export class DraggableCarouselComponent implements AfterContentChecked, OnDestroy {

  subs: Subscription[] = [];

  leftButtonDisabled: boolean = false;
  rightButtonDisabled: boolean = false;


  @Input() data: any[] = [];

  @ViewChild(DragScrollComponent) ds?: DragScrollComponent;
  @ContentChild(TemplateRef) itemTemplate?: TemplateRef<any>;

  ngAfterContentChecked(): void {
    if (this.subs.length < 2) {
      let sub = this.ds?.reachesLeftBound.subscribe({
        next: (reached: boolean) => {
          this.leftButtonDisabled = reached;
        }
      });
      if (sub) this.subs.push(sub);

      sub = this.ds?.reachesRightBound.subscribe({
        next: (reached: boolean) => {
          this.rightButtonDisabled = reached;
        }
      });
      if (sub) this.subs.push(sub);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {s.unsubscribe()});
  }

  moveLeft() {
    this.ds?.moveLeft();
  }

  moveRight() {
    this.ds?.moveRight();
  }

  canMoveLeft(): boolean {
    return ( this.ds || false ) && this.ds.currIndex > 0;
  }

  canMoveRight(): boolean {
    return ( this.ds || false ) && this.ds.currIndex < this.ds.indexBound;
  }
}
