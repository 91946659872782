import { Injectable } from "@angular/core";
import { User } from '../../model/user.model';

export class WindowObject extends Window {
  dataLayer: any;
}

@Injectable({
  providedIn: "root",
})
export class DataLayerService {
  private window;
  constructor() {
    this.window = window as unknown as WindowObject; // initialize the window to what we get from our window service
  }
  private pingHome(obj: any) {
    if (obj) this.window.dataLayer.push(obj);
  }

  logPageView(url: string) {
    const hit = {
      event: "content-view",
      pageName: url,
    };
    this.pingHome(hit);
  }
  logEvent(event: any, category: string, action: any, label: any) {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label,
    };
    this.pingHome(hit);
  }

  /**
   *
   * @param groupName name of the carousel
   */
  logSeeAllClicked(groupName: string) {
    const hit = {
      event: 'cine.click.see-all',
      containerName: groupName
    };
    this.pingHome(hit);
  }

  logMovieCardClicked(movieId: any, containerName: string) {
    const hit = {
      event: "cine.click.movie-card",
      movieId: movieId,
      containerName: containerName
    };
    this.pingHome(hit);
  }


  logTopBoxOfficeTabView(tab: any) {
    const hit = {
      event: "cine.topboxoffice.tabview",
      tabName: tab,
    };
    this.pingHome(hit);
  }


  logSearchEvent(keyword: any) {
    const hit = {
      event: "cine.search",
      keyword: keyword,
    };
    this.pingHome(hit);
  }

  logChangeLanguage(language: any) {
    const hit = {
      event: "cine.language-change",
      language: language,
    };
    this.pingHome(hit);
  }

  logSignin(user: User) {
    const hit = {
      event: "cine.signin",
      userId: user?.user_id,
      userEmail: user?.email,
      userPhone: user?.phone
    };
    this.pingHome(hit);
  }

  logSignup(user: User) {
    const hit = {
      event: "cine.signup",
      userId: user?.user_id,
      userEmail: user?.email,
      userPhone: user?.phone
    };
    this.pingHome(hit);
  }


  // Add more custom methods as needed by your app.
}
