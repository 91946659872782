import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Movie } from '../../../../model/movie.model';
import { scrollToTop } from '../../util/helper';

@Component({
  selector: 'app-compare-movie-link',
  templateUrl: './compare-movie-link.component.html',
  styleUrl: './compare-movie-link.component.scss'
})
export class CompareMovieLinkComponent {
  @Input() movies: Movie[] = [];
  @Input() compareUrlStack: string[] = ['pages', 'comparison'];
  constructor(private router: Router) { }
  gotoCompareMovie(): void {
    this.router.navigate(this.compareUrlStack, {
      queryParams: { movieIds: this.movies.map(m => m.id).slice(0, 3).join(',') }
    }).then(() => { scrollToTop() });
  }
}
