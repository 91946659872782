import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cine-flash-score',
  templateUrl: './cine-flash-score.component.html',
  styleUrl: './cine-flash-score.component.scss'
})
export class CineFlashScoreComponent {
  @Input() score: number = 0;
  @Input() frameStyleClass: string = '';
}
