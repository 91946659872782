import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rounded-card',
  templateUrl: './rounded-card.component.html',
  styleUrl: './rounded-card.component.scss'
})
export class RoundedCardComponent {
  @Input() active: boolean = false;
  @Input() width?:number;
  @Input() height?: number;
  @Input() gap?: number;
  @Input() additionalClass: string = '';
  @Input() showShadowbox: boolean = false;

  get style():any {
    let s:any = {};
    if (this.width) s['width'] = this.width + 'px';
    if (this.height) s['height'] = this.height + 'px';
    if (this.gap) s['gap'] = this.gap + 'px';
    return s;
  }

  get widthClass(): string { return this.width? `w-[${this.width}px]` : ''; }
  get heightClass(): string { return this.height? `h-[${this.height}px]` : ''; }
}
