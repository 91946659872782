import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Movie } from '../../../../model/movie.model';

@Component({
  selector: 'app-movies-carousel',
  templateUrl: './movies-carousel.component.html',
  styleUrl: './movies-carousel.component.scss'
})
export class MoviesCarouselComponent {
  @Input() hideDayDate: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() movies: Movie[] = [];
  @Input() label: string = '';
  @Input() showTitle: boolean = true;
  @Input() isUpcomingMovie: boolean = false;
  @Input() showYear: boolean = true;
  @Output() seeAllMoviesClicked: EventEmitter<any> = new EventEmitter<any>();

}
