import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-percent-change-pill',
  templateUrl: './percent-change-pill.component.html',
  styleUrl: './percent-change-pill.component.scss'
})
export class PercentChangePillComponent {
  @Input() value: number = 0;

  get isMinus(): boolean {
    return this.value < 0;
  }

  get text(): string {
    return `${this.isMinus? '' : '+' }${this.value}%`;
  }
}
