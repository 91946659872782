import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData } from 'chart.js';
import { Observable, of, tap } from 'rxjs';
import { Endpoint } from '../../../../constants/endpoint.constant';
import { CountryType } from '../../../../enums/country-type.enum';
import { PeriodType } from '../../../../enums/period-type';
import { IApiResponseDetail, IDetailResponse, IListResponse, IParamsMovieDirectory, IParamsTableData } from '../../../../interface/common.interface';
import { Movie, MovieBoxOfficeInfo, MovieDailyShowtime, MovieGenreOrRating, SearchMovie } from '../../../../model/movie.model';
import { BaseService } from '../../../../services/base.service';
import { DataLayerService } from '../../../../shared/services/data-layer.service';
import { converEnumToParamValue, getCountryParamValue, scrollToTop } from '../../util/helper';
import { FakeChartData, FakeDemographicChartData } from './fake-chart-data';

@Injectable({
  providedIn: 'root',
})
export class MovieService extends BaseService {
  genresList: MovieGenreOrRating[] = [];
  ratingList: MovieGenreOrRating[] = [];

  constructor(override http: HttpClient, private router: Router, private dataLayerService: DataLayerService) {
    super(http);
  }

  searchMovies(keyword: string): Observable<IApiResponseDetail<SearchMovie>> {
    this.dataLayerService.logSearchEvent(keyword);
    return this.getRequest(Endpoint.SEARCH_MOVIES, {name: keyword, matchType: 'start'});
  }

  getLatestMovies(params: IParamsTableData, spinneroff = false):Observable<IListResponse<Movie>['list']> {
    return this.getList<Movie>(Endpoint.LATEST_MOVIES, params, spinneroff);
  }

  getTopBoxOffice(period: PeriodType, countryType: CountryType, params: IParamsTableData, spinneroff = false):Observable<IListResponse<Movie>['list']> {
    return this.getList<Movie>(Endpoint.TOP_BOX_OFFICE(period), {...params, type: converEnumToParamValue( countryType )}, spinneroff );
  }

  getDailyShowtime(params: IParamsTableData):Observable<IListResponse<Movie>['list']> {
    return this.getList<Movie>(Endpoint.DAILY_SHOWTIME, {...params}, true );
  }

  getDailyShowtimeChart(movieIds: number[], date_end?: string):Observable<IDetailResponse<MovieDailyShowtime[]>['detail']> {
    return this.getRequestSimplified<MovieDailyShowtime[]>(Endpoint.DAILY_SHOWTIME_CHART, {movie_ids: movieIds.join(','),
      ...(date_end ? { date_end } : null)
    }, true );
  }

  getUpcomingMovies(monthPeriod: string, params: IParamsTableData, spinneroff = false):Observable<IListResponse<Movie>['list']> {
    return this.getList<Movie>(Endpoint.UPCOMING_MOVIES(monthPeriod), params, spinneroff);
  }

  getMovieDirectory(params: IParamsMovieDirectory):Observable<IListResponse<Movie>['list']> {
    return this.getList<Movie>(Endpoint.MOVIE_DIRECTORY, params );
  }

  /**
   * This method is for getting data on the Top Box Office page,
   * not the table on the Home page
   */
  getTopBoxOfficePage(period: string, params: any):Observable<IListResponse<MovieBoxOfficeInfo>['list']> {
    return this.getList<MovieBoxOfficeInfo>(Endpoint.TOP_BOX_OFFICE_PAGE(period), params )
      .pipe(
        tap((res: IListResponse<MovieBoxOfficeInfo>['list']) => {
          res.content.map((mov: MovieBoxOfficeInfo) => {
            mov.score = Number(Number(mov.score));
            mov.change = Number(Number(mov.change)?.toFixed(0));
            mov.datePeriodComposite = {
              date_start: new Date(mov.date_start),
              date_end: new Date(mov.date_end)
            }
            return mov;
          })
          return res;
        })
      );
  }

  /**
     * Gets data for top box office detail
     */
  getTopBoxOfficeDetail(period: string, params: any):Observable<IListResponse<Movie>['list']> {
    return this.getList<Movie>(Endpoint.TOP_BOX_OFFICE_DETAIL(period), params )
      .pipe(
        tap((res: IListResponse<Movie>['list']) => {
          res.content.map((mov: Movie) => {
            mov.score = Number(Number(mov.score));
            mov.change = Number(Number(mov.change)?.toFixed(0));
            return mov;
          })
          return res;
        })
      );
  }
  
  getMovieDetails(movieId: number): Observable<IDetailResponse<Movie>['detail']> {
    return this.getRequestSimplified(Endpoint.MOVIE_DETAIL, {id: movieId});
  }

  getMovieGenres(): Observable<IListResponse<MovieGenreOrRating>['list']> {
    return this.getList<MovieGenreOrRating>(Endpoint.MOVIE_GENRES);
  }

  getMovieRatings(): Observable<IListResponse<MovieGenreOrRating>['list']> {
    return this.getList<MovieGenreOrRating>(Endpoint.MOVIE_RATING_CATEGORIES);
  }

  initializeGenreListCache(): void{
    this.getMovieGenres().subscribe((list: IListResponse<MovieGenreOrRating>['list']) => {
      this.genresList = list.content;
    });
  }

  initializeRatingListCache(): void{
    this.getMovieRatings().subscribe((list: IListResponse<MovieGenreOrRating>['list']) => {
      this.ratingList = list.content;
    });
  }

  openDetail(movieId: number): void {
    this.router.navigate(['pages', 'directory', 'detail', movieId]);
    scrollToTop();
  }

  getAdmissionChart(movieIds: number[], countryType: CountryType, periodType: string): Observable<IDetailResponse<ChartData[]>['detail']> {
    return this.getChartData(Endpoint.CHART_ADMISSION, movieIds, countryType, periodType);
  }

  getGrossChart(movieIds: number[], countryType: CountryType, periodType: string): Observable<IDetailResponse<ChartData[]>['detail']> {
    return this.getChartData(Endpoint.CHART_GROSS, movieIds, countryType, periodType);
  }

  getShowtimesChart(movieIds: number[], countryType: CountryType, periodType: string): Observable<IDetailResponse<ChartData[]>['detail']> {
    return this.getChartData(Endpoint.CHART_SHOWTIMES, movieIds, countryType, periodType);
  }

  getDemographicChart(movieIds: number[], countryType: CountryType, periodType: string): Observable<IDetailResponse<ChartData[]>['detail']> {
    return this.getChartData(Endpoint.CHART_DEMOGRAPHIC, movieIds, countryType, periodType);
  }

  getChartData(endpoint: string, movieIds: number[], countryType: CountryType, periodType: string): Observable<IDetailResponse<ChartData[]>['detail']> {
    const periode_range = periodType===PeriodType.WeeklyByReleaseDate ? PeriodType.Weekly : periodType;
    return this.getRequestSimplified(endpoint, {
      movie_ids: movieIds.join(','),
      type: getCountryParamValue(countryType),
      periode_range,
      ...(periodType === PeriodType.WeeklyByReleaseDate && { compare_by_week: 1 })
    });
  }

  getFakeChartData(): Observable<any> {
    return of(FakeChartData);
  }

  getFakeDemographicChartData(): Observable<any> {
    return of(FakeDemographicChartData);
  }

}
