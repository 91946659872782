import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrl: './otp-input.component.scss'
})
export class OtpInputComponent implements OnChanges {
  @Input() formCtrl!: FormControl;
  @Input() errorMessage: string = '';

  otpConfig: any = this.initializeConfig();

  initializeConfig() {
    return {
      length: 6,
      containerClass: 'cine-otp',
      allowNumbersOnly: true,
      isPasswordInput: false,
      inputClass: `cine-otp-input ${this.errorMessage ? 'cine-otp-error' : ''}`,
      placeholder: '0'
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.otpConfig = this.initializeConfig();
    }
  }
}
