<div class="fgroup">
  <app-field-label
    [isMandatory]="isMandatory"
    [labelKey]="fieldNameKey"
  ></app-field-label>

  <span class="p-input-icon-right w-full">
    @if (!ctrl) { } @else if (ctrl && isDefaultOrText()) {
    <input
      #textInput
      type="text"
      pInputText
      (focus)="onFocus.emit($event)"
      class="!pr-[30px] w-full {{ inputStyleClass }}"
      [formControl]="ctrl"
      [placeholder]="placeholder | translate"
    />
    } @else if (controlType === 'password') {
    <p-password
      #passwordInput
      [formControl]="ctrl"
      [feedback]="false"
      (onFocus)="onFocus.emit($event)"
      [toggleMask]="toggleMask"
      [inputStyleClass]="inputStyleClass"
      [placeholder]="placeholder | translate"
      autocomplete="new-password"
    />
    } @else if (controlType === 'date') {
    <p-inputGroup>
      <p-inputGroupAddon class="items-center !pr-[6px] !min-w-0">
        <i [ariaDisabled]="disabled"
          class="pi pi-calendar" [ngClass]="{'cursor-pointer':!disabled}"
          (click)="disabled?$event.stopPropagation():toggleCalendar($event)"
        ></i>
      </p-inputGroupAddon>
      <input
        #dateInput
        type="date"
        (focus)="onFocus.emit($event)"
        pInputText
        class="!border-l-0 !pl-0 !pr-[30px] w-full {{ inputStyleClass }}"
        [formControl]="ctrl"
        [placeholder]="placeholder | translate"
      />
    </p-inputGroup>
    }

    <i
      class="cine-error pi pi-exclamation-circle text-danger-400 w-[13px] h-[13px]"
    ></i>
  </span>

  <app-field-errors
    [excludeKeys]="excludeErrorKeys"
    [form]="ngForm"
    [fieldName]="fieldName"
    [fieldNameKey]="errorFieldNameKey || fieldNameKey"
    [customErrors]="customErrors"
  >
  </app-field-errors>
</div>

<p-overlay
[(visible)]="calendarOverlayVisible"
[responsive]="{
  breakpoint: '640px',
  direction: 'bottom',
  contentStyleClass: 'h-20rem'
}"
contentStyleClass="mt-[-36px] ml-[-4px] p-4 surface-overlay shadow-2 border-round"
>
<ng-template pTemplate="content" let-option>
  <p-calendar #cineCalendar
    [(ngModel)]="calendarDate"
    [inline]="true"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (onSelect)="updateDateValue()" />
</ng-template>
</p-overlay>
