@if (isMobile) {
  <app-movies-carousel
    [movies]="displayMovies"
    [isUpcomingMovie]="true"
    [showTitle]="false"
    label="COMMON.UPCOMING_MOVIES"
    [isLoading]="isLoading"
    [hideDayDate]="true"
  ></app-movies-carousel>
} @else {
    <div class="h-[max-content] w-full relative" [ngClass]="{'min-h-[352px]':isLoading}">
      @if (evenDisplayMovies.length <1) {
        <div class="w-full h-full min-h-[280px] flex items-center justify-center">
          {{'COMMON.NO_RECORDS_FOUND'|translate}}
        </div>
      }
      @if (isLoading) {
        <app-loader-animation [isOverlay]="false" class="h-full !absolute top-0 right-0 bottom-0 left-0"></app-loader-animation>
      }
      <div class="upcoming-movie-panel flex-wrap">
        @for(movie of evenDisplayMovies; track movie.id) {
          <app-card-movie-detail-horizontal
            containerName="COMMON.UPCOMING_MOVIES"
            [hideDayDate]="true" [movie]="movie" class="p-[16px] !h-auto"></app-card-movie-detail-horizontal>
        }
      </div>
    </div>
}
