import { addDays, format, getDate, getMonth, getYear, startOfWeek } from 'date-fns';
import { Observable, map, takeWhile, timer } from 'rxjs';
import { zeroPad } from './helper';

export const getYesterday = (date: Date): Date => {
  return addDays(date, -1);
}

export const getLastWeekMonday = (date: Date): Date => {
  return addDays(getClosestPreviousMonday(date), -7);
};

export const getClosestPreviousSunday = (date: Date): Date => {
  return addDays( getLastWeekMonday(date), 6);
}

export const getClosestPreviousMonday = (date: Date): Date => {
  return startOfWeek(date, { weekStartsOn: 1});
}

export const getClosestSunday = (date: Date): Date => {
  return addDays( getClosestPreviousMonday(date), 6 );
}

export const formatSecondsToDuration = (seconds: number): string => {
  const duration = { minutes: Math.floor(seconds / 60), seconds: seconds % 60 };
  return `${zeroPad(duration.minutes, 2)}:${zeroPad(duration.seconds, 2)}`;
}

export const createCountdownTimer$ = (seconds: number): Observable<any> => {
  return timer(0, 1000).pipe(
    map((tick) => seconds - tick),
    takeWhile(time => time >= 0) // Stop the timer when it reaches 0
  );
}

export const justTheDate = (dt: Date): Date => { return new Date(dt.toDateString()) }
export const formatDateISO8601 = (dt: Date): string => { return format(dt, 'yyyy-MM-dd')}

export const getCurrentYear = () => { return getYear(new Date()) };
export const getCurrentMonth = () => { return getMonth(new Date()) };
export const getCurrentDayOfMonth = () => { return getDate(new Date()) };
