import { Component } from '@angular/core';

@Component({
  selector: 'app-search-dropdown-panel',
  templateUrl: './search-dropdown-panel.component.html',
  styleUrl: './search-dropdown-panel.component.scss'
})
export class SearchDropdownPanelComponent {
  movies: any[] = [
    {
      id: 1,
      title: 'The Batman',
      year: 2022,
      genre: 'Thriller/Crime',
      url: '../../../../assets/images/sample-batman.svg'
    },
    {
      id: 1,
      title: 'The Dark Knight',
      year: 2008,
      genre: 'Thriller/Drama',
      url: '../../../../assets/images/sample-darkknight.svg'
    },
    {
      id: 1,
      title: 'The Grand Budapest Hotel',
      year: 2008,
      genre: 'Thriller/Action',
      url: '../../../../assets/images/sample-budapest.svg'
    },
    {
      id: 1,
      title: 'The Killing of a Sacred Deer',
      year: 2008,
      genre: 'Thriller/Action',
      url: '../../../../assets/images/sample-killing.svg'
    },
    {
      id: 1,
      title: 'The Menu',
      year: 2008,
      genre: 'Thriller/Action',
      url: '../../../../assets/images/sample-themenu.svg'
    },

  ];

  casts: any[] = [
    {
      id: 1,
      name: 'David Thewlis',
      url: '../../../../assets/images/sample-david.svg'
    },
    {
      id: 1,
      name: 'Charlize Theron',
      url: '../../../../assets/images/sample-charlize.svg'
    },

  ]
}
