import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MovieSortOption } from '../../../../enums/movie-sort-option.enum';
import { IListResponse, IParamsTableData } from '../../../../interface/common.interface';
import { Movie } from '../../../../model/movie.model';
import { MovieService } from '../../services/movie/movie.service';
import { BasePageComponent } from '../base-page/base-page.component';

@Component({
  selector: 'app-latest-movies',
  templateUrl: './latest-movies.component.html',
  styleUrl: './latest-movies.component.scss'
})
export class LatestMoviesComponent extends BasePageComponent implements OnInit {
  data: Movie[] = [];

  constructor(
    override route: ActivatedRoute,
    private router: Router,
    private movieService: MovieService
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.addSubscription(
      this.movieService.getLatestMovies({} as IParamsTableData, true).subscribe({
        next: (res: IListResponse<Movie>['list']) => {
          this.data = res.content;
          this.isLoading = false;
        }
      })
    )
  }

  seeAllClicked():void {
    this.router.navigate(['pages','directory'], {
      queryParams: {
        sort: MovieSortOption.Latest.value
      }
    })
  }
}
