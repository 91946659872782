import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountryService } from '../../services/country/country.service';
import { BasePageComponent } from '../base-page/base-page.component';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss'
})
export class PhoneInputComponent extends BasePageComponent implements OnChanges {

  @Input() ngForm!: FormGroup;
  @Input() countryCodeFieldName: string = '';
  @Input() phoneNoFieldName: string = '';
  @Input() isRequired: boolean = false;
  @Input() labelKey: string = 'COMMON.PHONE_NO';
  @Input() disabled: boolean = false;
  @Input() inputStyleClass: string = '';
  @Input() dropdownStyleClass: string = '';
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  get codeCtrl(): FormControl | null { return this.ngForm?.get(this.countryCodeFieldName) as FormControl }
  get phoneCtrl(): FormControl | null { return this.ngForm?.get(this.phoneNoFieldName) as FormControl }
  @ViewChild('phoneNumberInput') phoneNumberInput?: ElementRef<any>;
  countryCodeList: string[] = [];

  constructor(protected override route: ActivatedRoute, private countryService: CountryService, private cdr: ChangeDetectorRef) {
    super(route);
    this.addSubscription(
      this.countryService.countries$.subscribe(countries => {
        this.countryCodeList = countries.map(c => c.dial_code);
        if ( !this.countryCodeList.includes(this.codeCtrl?.value) ) {
          this.codeCtrl?.setValue(this.countryCodeList[0]);
        }
      })
    );
  }

  allChangesComplete(changes: SimpleChanges): boolean {
    return ['ngForm', 'countryCodeFieldName', 'phoneNoFieldName', 'isRequired', 'disabled'].reduce(
      (p, v) => {
        return p && changes[v] && changes[v].currentValue;
      }, true
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.allChangesComplete(changes)) {
      if  (this.disabled) {
        this.ngForm.get(this.countryCodeFieldName)?.disable();
        this.ngForm.get(this.phoneNoFieldName)?.disable();
      }
    }
  }

  focus(): void {
    this.phoneNumberInput?.nativeElement.focus();
    this.cdr.detectChanges();
  }
}
