<div class="p-input-icon-right flex flex-col">
  <p-autoComplete
    #searchBox
    styleClass="w-full cine-searchbox"
    inputStyleClass="w-full"
    [delay]="400"
    [group]="true"
    [(ngModel)]="keyword"
    [optionLabel]="'name'"
    [suggestions]="filteredGroups"
    (completeMethod)="searchMovie($event.query)"
    (ngModelChange)="ngModelChange($event)"
    [placeholder]="'ADD_MOVIE_DIALOG.SEARCH_PLACEHOLDER' |translate"
  >
    <ng-template let-group pTemplate="group">
      @if (group.value === 'movies' && group.items.length > 0) {
        <div class="flex align-items-center text-neutral-400 px-[0.75rem] py-[1rem]">
          <span>{{ 'COMMON.'+group.label | translate }}</span>
        </div>
      }
      @if (group.value === 'casts' && group.items.length > 0) {
        <div class="flex align-items-center text-neutral-400 px-[0.75rem] py-[1rem]">
          <span>{{ 'COMMON.'+group.label | translate }}</span>
        </div>
      }
    </ng-template>
    <ng-template pTemplate="empty">
      <div class="text-neutral-400 font-normal flex items-center justify-center"
        translate="ADD_MOVIE_DIALOG.NO_RESULT"
      ></div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      @if (item.value.type === 'movie') {
        <div class="flex items-center gap-[8px] justify-between py-[0.5rem] px-[1.5rem]">
          <div class="flex items-center gap-[8px]">
            <app-image-card
              [url]="item.value.image"
              [alt]="item.value.name"
              frameStyleClass="!w-[40px] !h-[40px]"
              fallbackImageType="movie_poster"
              [showFallbackText]="false"
            ></app-image-card>
            <div class="flex flex-col">
              <div class="text-neutral-600 font-medium text-base">
                {{ item.value.name }}
              </div>
              <div class="flex">
                <div class="text-neutral-400 font-medium text-base">
                  {{ item.value.year }}
                </div>
                &nbsp;
                &#8226;
                &nbsp;
                <div class="text-neutral-400 font-medium text-base">
                  {{ item.value.genre }}
                </div>
              </div>
            </div>
          </div>
          <span>
            <i class="pi pi-angle-right"></i>
          </span>
        </div>
      } @else {
        <div class="flex items-center gap-[8px] py-[0.5rem] px-[1.5rem]">
          <div class="text-neutral-600 font-medium text-base">
            {{ item.value.name }}
          </div>
        </div>
      }
    </ng-template>
  </p-autoComplete>
  <i class="pi pi-search text-neutral-500"></i>
</div>

