import { ToastType } from '../enums/toast-type.enum';

export class Toast {
  type: ToastType = ToastType.Info;
  message: string = '';
  toastId: number = 0;
  keepAfterRouteChange: boolean = false;
  constructor(init?: Partial<Toast>) {
    Object.assign(this, init);
  }
}
