<!-- The Actual Carousel -->
<drag-scroll #nav class="" [scrollbar-hidden]="false">
  @for(item of data; track $index) {
    <div drag-scroll-item class="drag-item">
      <ng-container
        [ngTemplateOutlet]="itemTemplate || null"
        [ngTemplateOutletContext]="{
          $implicit: item
        }"
      ></ng-container>
    </div>

  }
  <ng-content></ng-content>
</drag-scroll>
@if (data.length <1) {
  <div class="w-full h-full min-h-[280px] flex items-center justify-center">
    {{'COMMON.NO_RECORDS_FOUND'|translate}}
  </div>
}

<!-- Navigation buttons -->
 @if (data.length>0) {
  <div class="mt-[40px] flex flex-row gap-[16px] lg:gap-[32px]">
    <app-cine-button
      buttonId="draggable-carousel_button-arrow-left"
      (onClick)="moveLeft()"
      [disabled]="leftButtonDisabled"
      styleClass="carousel-nav-button"
      icon="pi pi-arrow-left"
    ></app-cine-button>
    <app-cine-button
      buttonId="draggable-carousel_button-arrow-right"
      (onClick)="moveRight()"
      [disabled]="rightButtonDisabled"
      styleClass="carousel-nav-button"
      icon="pi pi-arrow-right"
    ></app-cine-button>
  </div>
 }
