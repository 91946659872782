import { DecimalPipe } from '@angular/common';
import { Component, ContentChildren, EventEmitter, Input, Output, Pipe, QueryList, ViewChild } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Subject, debounceTime } from 'rxjs';
import { ScrollService } from '../../services/scroll.service';
import { getNestedProperty } from '../../util/helper';
import { getDefaultLazyLoadEvent } from '../../util/table.util';
import { CineTableColumnComponent } from '../cine-table-column/cine-table-column.component';

@Component({
  selector: 'app-cine-table',
  templateUrl: './cine-table.component.html',
  styleUrl: './cine-table.component.scss'
})
export class CineTableComponent {
  @Input() lazy: boolean = true;
  @Input() selectedData?: any[];
  @Input() selectAll: boolean = false;

  @Input() totalRecords: number = 0;
  @Input() loading: boolean = false;
  @Input() hideLoaderAnim: boolean = false;
  @Input() useFilters: boolean = false;

  @Input() data: any[] = [];
  @Input() selectable: boolean = false;

  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() pageSize: number = this.pageSizeOptions[0];
  @Input() currentPage: number = 0;
  get firstRecord(): number {
    return this.currentPage * this.pageSize;
  }

  @Input() scrollRoot: boolean = false;

  @Output() onLazyLoad: EventEmitter<TableLazyLoadEvent> = new EventEmitter<TableLazyLoadEvent>();

  @ContentChildren(CineTableColumnComponent) columns: QueryList<CineTableColumnComponent> = new QueryList<CineTableColumnComponent>();
  @ViewChild(Table) table?: Table;

  numberPipe: Pipe = DecimalPipe;
  lastLazyLoadEvent: TableLazyLoadEvent = getDefaultLazyLoadEvent();
  private eventSubject = new Subject<any>();

  initializing: boolean = true;  
  getValue(rowData: any, column: CineTableColumnComponent): any {
    let val = getNestedProperty(rowData, column.property);
    if (column.transformValue) val = column.transformValue(val);
    return val;
  }

  isNumberColumn(column: CineTableColumnComponent): boolean {
    return column.dataType === 'number';
  }

  private _cachePage: { first: number|null|undefined; rows: number|null|undefined } = { first: undefined, rows: undefined };
  set cachePage(values: { first: number|null|undefined; rows: number|null|undefined }) {
    this._cachePage = { first: values.first, rows: values.rows };
  }
  get cachePage() {
    return this._cachePage;
  }

  lazyLoad(event: TableLazyLoadEvent) {
    const page = {first: event.first, rows: event.rows};
    if (JSON.stringify(this.cachePage) !== JSON.stringify(page)) {
      if (this.scrollRoot) {
        this.scrollSvc.scrollRoot();
      } else this.scrollSvc.scrollToId('cine_table_top');
    }
    this.cachePage = page;
    this.eventSubject.next(event);
    this.lastLazyLoadEvent = event;

  }

  pageSizeChanged() {
    this.lastLazyLoadEvent.rows = this.pageSize;
    this.lazyLoad(this.lastLazyLoadEvent);
  }

  constructor(private scrollSvc: ScrollService) {
    this.initializing = true;
    this.setupLazyLoadEventEmitter();
  }

  setupLazyLoadEventEmitter(): void {
    this.eventSubject.pipe(
      debounceTime(300)  // Adjust the time as needed
    ).subscribe((data) => {
      this.onLazyLoad.emit(data);
      this.initializing = false;
    });
  }

  resetSort() {
    if (this.table) {
      this.table.sortField = null;
      this.table.sortOrder = 0;
      this.table.reset();
    }
  }

}
