import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { addDays, addMinutes } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, catchError, of, switchMap, tap } from 'rxjs';
import { Endpoint } from '../../../../constants/endpoint.constant';
import { Messages } from '../../../../constants/messages.constant';
import { SessionKey } from '../../../../constants/session.constants';
import { SignedOutReasons } from '../../../../enums/signed-out-reasons.enum';
import {
  IApiResponseDetail,
  IDetailResponse
} from '../../../../interface/common.interface';
import { PaymentData, Period } from '../../../../model/payment-data.model';
import { PhoneNo } from '../../../../model/phone.model';
import { SignInResponse } from '../../../../model/signin-response.model';
import { User } from '../../../../model/user.model';
import { BaseService } from '../../../../services/base.service';
import { DataLayerService } from '../../../../shared/services/data-layer.service';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    override http: HttpClient,
    private router: Router,
    private cookie: CookieService,
    private dataLayerService: DataLayerService,
    private toastService: ToastService
  ) {
    super(http);
    this.updateCurrentUser();
  }

  checkPhoneNo(phone: PhoneNo): Observable<IDetailResponse<any>['detail']> {
    return this.postRequestSimplified<any>(Endpoint.CHECK_REG, { phone });
  }

  postSignup(data: User): Observable<IApiResponseDetail<User>> {
    this.dataLayerService.logSignup(data);
    return this.postRequestRaw(Endpoint.SIGN_UP, data);
  }

  resendVerification(email: string, isSignUp: boolean = true): Observable<any> {
    return this.postRequestSimplified<any>(isSignUp? Endpoint.RESEND_VERIF : Endpoint.RESEND_VERIF_CHANGE_EMAIL, { email }).pipe(
      tap(() => { this.toastService.info(Messages.VERIF_LINK_RESENT);}),
      catchError( (err) => { this.toastService.error(Messages.VERIF_LINK_RESEND_ERROR); return err;})
    );
  }

  postPayment(payment: PaymentData): Observable<IDetailResponse<Period>> {
    return this.postRequestSimplified<Period>(Endpoint.PAYMENT, payment);
  }

  verifyPassword(userCreds: User, rememberUser: boolean): Observable<IApiResponseDetail<SignInResponse>> {
    return this.postRequestRaw(Endpoint.SIGN_IN, userCreds);
  }

  signIn(userCreds: User, rememberUser: boolean): Observable<IApiResponseDetail<SignInResponse>> {
    return this.postRequestRaw(Endpoint.SIGN_IN, userCreds).pipe(
      tap({
        next: (res: IApiResponseDetail<SignInResponse>) => {
          const token  = res.response_output.detail.access_token;
          const refreshToken  = res.response_output.detail.refresh_token;
          const user = res.response_output.detail.user!;
          this.dataLayerService.logSignin(user);
          if (user?.is_email_verified && token) {
            this.storeCurrentUser(user);
            this.storeToken(token);
            this.storeRefreshToken(refreshToken!);
          } else {
            this.storeUnverifiedUser(user!);
          }

          if (rememberUser) {
            // save user's login preference & credential & refresh token
            this.rememberUserLogin(user, !!userCreds.usingEmail, refreshToken);
          } else {
            this.removeRememberedUser();
            this.removeRefreshToken();
          }
        }
      })
    );
  }

  removeRememberedUser(): void {
    this.cookie.delete(SessionKey.REMEMBERED_USER);
    this.removeRefreshToken();
  }

  getRememberedUser(): User | null {
    const userString = this.cookie.get(SessionKey.REMEMBERED_USER);
    return userString ? JSON.parse(userString) : null;
  }

  rememberUserLogin(user: User, usingEmail: boolean, refreshToken?: string): void {
    if (refreshToken) this.storeRefreshToken(refreshToken);
    user.usingEmail = usingEmail;
    this.cookie.set(SessionKey.REMEMBERED_USER, JSON.stringify(user), {expires: addDays(new Date(), 30)});
  }

  checkAccount(userCreds: User): Observable<IApiResponseDetail<User>> {
    return this.postRequestRaw(Endpoint.CHECK_REG, userCreds);
  }

  sendOtp(userId: number): Observable<IApiResponseDetail<any>> {
    return this.postRequestRaw(Endpoint.SEND_OTP, { user_id: userId });
  }

  verifyOtp(userId: number, otp: string): Observable<any> {
    return this.postRequestSimplified(Endpoint.VERIFY_OTP, {
      user_id: userId,
      otp_code: otp,
    });
  }


  setupAccountMobileUser(user: User): Observable<IApiResponseDetail<User>> {
    return this.postRequestRaw(Endpoint.ACCOUNT_SETUP, {
      email: user.email,
      password: user.password,
      confirm_password: user.confirm_password,
    });
  }

  changePassword(
    password: string,
    confirmPassword: string
  ): Observable<IApiResponseDetail<any>> {
    return this.putRequestRaw(Endpoint.CHANGE_PASSWORD, {
      password,
      confirm_password: confirmPassword,
    });
  }

  checkEmailVerified(email: string): Observable<boolean> {
    return of(email.endsWith('.tech'));
  }

  changeEmail(
    email: string
  ): Observable<IApiResponseDetail<any>> {
    return this.putRequestRaw(Endpoint.CHANGE_EMAIL, {email});
  }

  validateRefreshToken(): Observable<SignInResponse> {
    return this.postRequestSimplified(Endpoint.REFRESH_TOKEN, {}, null, false, {
      Authorization: `Bearer ${this.getCurrentRefreshToken()}`
    })
  }

  refreshToken(rememberUser: boolean = false): Observable<SignInResponse> {
    let response: any = {};
    return this.validateRefreshToken().pipe(
      switchMap((value: SignInResponse) => {
        response = value;
        if (value.access_token) {
          this.storeToken(value.access_token);
          if (value.refresh_token) this.storeRefreshToken(value.refresh_token);
        }
        return this.getUserProfile();
      }),
      switchMap((user: User) => {
        let oldUser = this.getCurrentUser();
        user = { ...oldUser, ...user };
        this.storeCurrentUser(user);
        response.user = user;
        return of(response);
      })
    );
  }

  forgotPassword(email: string): Observable<IApiResponseDetail<any>> {
    return this.postRequestRaw(Endpoint.FORGOT_PASSWORD, { email });
  }

  verifyForgotPassword(
    token: string
  ): Observable<IApiResponseDetail<any>> {


    return this.getRequest(`${Endpoint.VERIFY_FORGOT_PASSWORD}${token}`);
  }

  getUserProfile(): Observable<User> {
    return this.getRequestSimplified(Endpoint.ACCOUNT_PROFILE);
  }


  /* Token and Session related functions */

  storeToken(token: string): void {
    const decoded = jwtDecode(token);
    this.cookie.set(SessionKey.TOKEN, token, this.cookieExpiry(decoded.exp));
  }

  storeRefreshToken(token: string): void {
    const decoded = jwtDecode(token);
    this.cookie.set(SessionKey.REFRESH_TOKEN, token, this.cookieExpiry(decoded.exp));
  }

  expiresIn5Min() {
    return {expires: addMinutes(new Date(), 5)};
  }

  cookieExpiry(exp?: number): any {
    return { expires: new Date( (exp ?? 0) * 1000)};
  }

  storeUnsignedUser(user: User): void {
    user.is_signed_in = false;
    this.cookie.set(SessionKey.UNSIGNED_USER, JSON.stringify(user), this.expiresIn5Min());
  }

  /* Unverified user is stored at max 5 minutes */
  storeUnverifiedUser(user: User): void {
    this.removeUnsignedUser();
    this.removeUser();
    this.cookie.set(SessionKey.UNVERIFIED_USER, JSON.stringify(user)); //, this.expiresIn5Min());
  }

  storeCurrentUser(user: User): void {
    this.removeUnverifiedUser();
    this.removeUnsignedUser();
    this.cookie.set(SessionKey.USER, JSON.stringify(user));
    this.updateCurrentUser();
  }

  getCurrentToken(): string | null {
    return this.cookie.get(SessionKey.TOKEN);
  }

  getCurrentRefreshToken(): string | null {
    return this.cookie.get(SessionKey.REFRESH_TOKEN);
  }

  getUnsignedUser(): User | null {
    const userString = this.cookie.get(SessionKey.UNSIGNED_USER);
    return userString ? JSON.parse(userString) : null;
  }

  /* Unverified user is a user that is not considered logged in */
  getUnverifiedUser(): User | null {
    const userString = this.cookie.get(SessionKey.UNVERIFIED_USER);
    return userString ? JSON.parse(userString) : null;
  }

  getCurrentUser() {
    const userString = this.cookie.get(SessionKey.USER);
    return userString ? JSON.parse(userString) : null;
  }

  currentUserObservable: BehaviorSubject<User|null> = new BehaviorSubject<User|null>(null);

  updateCurrentUser(): void {
    const userString = this.cookie.get(SessionKey.USER);
    this.currentUserObservable.next(userString ? JSON.parse(userString) : null);
  }

  removeToken(): void {
    this.cookie.delete(SessionKey.TOKEN);
  }

  removeRefreshToken(): void {
    this.cookie.delete(SessionKey.REFRESH_TOKEN);
  }

  removeUser(): void {
    this.cookie.delete(SessionKey.USER);
  }

  removeUnsignedUser(): void {
    this.cookie.delete(SessionKey.UNSIGNED_USER);
  }

  removeUnverifiedUser(): void {
    this.cookie.delete(SessionKey.UNVERIFIED_USER);
  }

  removeAllUserData(): void {
    this.removeToken();
    if (!this.getRememberedUser()) this.removeRefreshToken();
    this.removeUser();
    this.updateCurrentUser();
  }

  logout(refreshPage: boolean = true): void {
    this.router.navigate(['auth/signed-out'], { queryParams: { refreshPage }, replaceUrl: true, skipLocationChange: false });
  }

  gotoSignIn(): void {
    this.router.navigate(['auth', 'signin']);
  }

  sessionExpired(): void {
    this.removeAllUserData();
    this.router.navigate(['auth', 'unauthorized', SignedOutReasons.SessionExpired]);
  }

  notLoggedIn(): void {
    this.removeAllUserData();
    this.router.navigate(['auth', 'unauthorized', SignedOutReasons.NotLoggedIn]);
  }


}
