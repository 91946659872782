<div class="flex flex-row gap-[4px] items-center text-sm">
  <i
    [class]="
      isError
        ? 'pi pi-times text-danger-400'
        : 'pi pi-check text-success-500'
    "
  ></i>
  <span
    [class]="
      isError ? 'text-danger-400' : 'text-success-500'
    "
  >
    {{
      message
    }}
  </span>
</div>
