import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-button-take-me-home',
  templateUrl: './button-take-me-home.component.html',
  styleUrl: './button-take-me-home.component.scss'
})
export class ButtonTakeMeHomeComponent {
  @Input() styleClass: string = 'cine-button-primary';

  constructor(private router: Router) {}
  gotoHome():void {
    this.router.navigate(['/'])

  }
}
