<app-section-title
  text="COMMON.TOP_BOX_OFFICE"
  class="grow"
></app-section-title>

<p-tabView [(activeIndex)]="activeTabIndex" (onChange)="setAdmissionLabel($event)">
  @for (tabData of tabs; track tabData.title) {
  <p-tabPanel [header]="tabData.title | translate">
    <!-- Period & Type of Countries -->
    <div class="flex flex-row flex-wrap gap-[8px] py-[16px] items-center">
      <span class="grow text-secondary-500 text-sm"
        >{{ "COMMON.PERIOD" | translate }}: {{ startDate(tabData.period) | date }}
        @if (activeTabIndex > 0) {- {{ endDate(tabData.period) | date }} }
      </span>

      <p-dropdown
        [options]="countryTypes"
        [(ngModel)]="tabData.countryType"
        optionLabel="label"
        optionValue="value"
        (onChange)="loadData(tabData, {})"
      >
        <ng-template pTemplate="item" let-country>
          {{country.label | translate}}
        </ng-template>

        <ng-template pTemplate="selectedItem" let-country>
          {{ country.label | translate }}
        </ng-template>
      </p-dropdown>

    </div>


    <!-- Table of Box Office -->
    <app-box-office-table
      [data]="tabData"
      [admissionLabel]="admissionLabel"
      (onLazyLoad)="loadData(tabData, $event)"
    ></app-box-office-table>

    <div class="flex justify-center md:justify-end p-[0.75rem] w-full">
      <div class="w-full md:w-[50%] text-[0.75rem] text-center md:text-right">
        {{'TOP_BOX_OFFICE.DISCLAIMER'|translate}}
      </div>
    </div>
  </p-tabPanel>
  }
</p-tabView>
