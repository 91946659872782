import { Component, Input, OnInit } from '@angular/core';
import { BaseCardMovieComponent } from '../../../../shared/components/base-card-movie/base-card-movie.component';
import { formatMovieDuration, getReleaseYear } from '../../util/helper';

@Component({
  selector: 'app-card-movie-detail-horizontal',
  templateUrl: './card-movie-detail-horizontal.component.html',
  styleUrl: './card-movie-detail-horizontal.component.scss'
})
export class CardMovieDetailHorizontalComponent extends BaseCardMovieComponent implements OnInit {
  @Input() showImage: boolean = true;
  @Input() showDuration: boolean = true;
  @Input() showYear: boolean = true;
  @Input() hideDayDate: boolean = false;

  ngOnInit(): void {
    if (this.movie?.id === -1) {
      this.showImage = false;
      this.openDetailOnclicked = false;
    }
  }

  getYear():number  {
    return getReleaseYear(this.movie);
  }

  getGenre(): string {
    return this.movie?.movie_genre?.join(' / ') ?? '';
  }

  getDuration(): string {
    return formatMovieDuration(this.movie?.duration ?? 0);
  }
}
