import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { CookieService } from 'ngx-cookie-service';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { EllipsisModule } from 'ngx-ellipsis';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { SortAltIcon } from 'primeng/icons/sortalt';
import { SortAmountDownIcon } from 'primeng/icons/sortamountdown';
import { SortAmountUpAltIcon } from 'primeng/icons/sortamountupalt';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayModule } from 'primeng/overlay';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { StepperModule } from 'primeng/stepper';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { CineAlertStaticComponent } from '../../standalone/cine-alert-static/cine-alert-static.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { BoxOfficeTableComponent } from './components/box-office-table/box-office-table.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonTakeMeHomeComponent } from './components/button-take-me-home/button-take-me-home.component';
import { CardAddItemButtonComponent } from './components/card-add-item-button/card-add-item-button.component';
import { CardMovieDetailHorizontalComponent } from './components/card-movie-detail-horizontal/card-movie-detail-horizontal.component';
import { CardMovieDetailVerticalComponent } from './components/card-movie-detail-vertical/card-movie-detail-vertical.component';
import { CardMovieSynopsisComponent } from './components/card-movie-synopsis/card-movie-synopsis.component';
import { CardMovieUpcomingComponent } from './components/card-movie-upcoming/card-movie-upcoming.component';
import { CardPageComponent } from './components/card-page/card-page.component';
import { CardPlanComponent } from './components/card-plan/card-plan.component';
import { CastCrewDialogComponent } from './components/cast-crew-dialog/cast-crew-dialog.component';
import { CineButtonComponent } from './components/cine-button/cine-button.component';
import { CineFlashScoreComponent } from './components/cine-flash-score/cine-flash-score.component';
import { CineTableColumnComponent } from './components/cine-table-column/cine-table-column.component';
import { CineTableComponent } from './components/cine-table/cine-table.component';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { CompareMovieLinkComponent } from './components/compare-movie-link/compare-movie-link.component';
import { DraggableCarouselComponent } from './components/draggable-carousel/draggable-carousel.component';
import { FieldErrorsComponent } from './components/field-errors/field-errors.component';
import { FieldFeedbackComponent } from './components/field-feedback/field-feedback.component';
import { FieldLabelComponent } from './components/field-label/field-label.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { InputControlComponent } from './components/input-control/input-control.component';
import { LatestMoviesComponent } from './components/latest-movies/latest-movies.component';
import { LoaderAnimationComponent } from './components/loader-animation/loader-animation.component';
import { LockedFeatureComponent } from './components/locked-feature/locked-feature.component';
import { MobileFilterEntryComponent } from './components/mobile-filter-entry/mobile-filter-entry.component';
import { MobileFilterOptionsPopupComponent } from './components/mobile-filter-options-popup/mobile-filter-options-popup.component';
import { MovieComparePairBoxComponent } from './components/movie-compare-pair-box/movie-compare-pair-box.component';
import { MovieDetailsChartsComponent } from './components/movie-details-charts/movie-details-charts.component';
import { MovieDetailsIndividualChartComponent } from './components/movie-details-individual-chart/movie-details-individual-chart.component';
import { MovieDetailsScoreRatingsComponent } from './components/movie-details-score-ratings/movie-details-score-ratings.component';
import { MovieGenrePillComponent } from './components/movie-genre-pill/movie-genre-pill.component';
import { MovieGenresTextComponent } from './components/movie-genres-text/movie-genres-text.component';
import { MoviesCarouselComponent } from './components/movies-carousel/movies-carousel.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PasswordFieldsComponent } from './components/password-fields/password-fields.component';
import { PasswordTickMarksComponent } from './components/password-tick-marks/password-tick-marks.component';
import { PercentChangePillComponent } from './components/percent-change-pill/percent-change-pill.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { RoundedCardComponent } from './components/rounded-card/rounded-card.component';
import { SaveConfirmDialogComponent } from './components/save-confirm-dialog/save-confirm-dialog.component';
import { ScoreBoxComponent } from './components/score-box/score-box.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SearchDropdownPanelComponent } from './components/search-dropdown-panel/search-dropdown-panel.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { SeeMoreButtonComponent } from './components/see-more-button/see-more-button.component';
import { TickMarkComponent } from './components/tick-mark/tick-mark.component';
import { TopBoxOfficeComponent } from './components/top-box-office/top-box-office.component';
import { UpcomingMovieMonthlyPanelComponent } from './components/upcoming-movie-monthly-panel/upcoming-movie-monthly-panel.component';
import { UpcomingMoviesComponent } from './components/upcoming-movies/upcoming-movies.component';
import { VersusCircleComponent } from './components/versus-circle/versus-circle.component';
import { YoutubePlayerDialogComponent } from './components/youtube-player-dialog/youtube-player-dialog.component';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { DollarCurrencyPipe } from './directives/dollar-currency.directive';
import { DynamicPipe } from './directives/dynamic.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { RupiahCurrencyPipe } from './directives/rupiah-currency.directive';

@NgModule({
  declarations: [
    SearchBoxComponent,
    SearchDropdownPanelComponent,
    ImageCardComponent,
    CardMovieSynopsisComponent,
    DraggableCarouselComponent,
    LatestMoviesComponent,
    SectionTitleComponent,
    SeeMoreButtonComponent,
    UpcomingMoviesComponent,
    CardMovieDetailHorizontalComponent,
    UpcomingMovieMonthlyPanelComponent,
    CineButtonComponent,
    CineTableComponent,
    TopBoxOfficeComponent,
    CineTableColumnComponent,
    DynamicPipe,
    PercentChangePillComponent,
    CineFlashScoreComponent,
    BasePageComponent,
    FieldLabelComponent,
    TickMarkComponent,
    FieldErrorsComponent,
    CardPlanComponent,
    RoundedCardComponent,
    BreadcrumbComponent,
    BadgeComponent,
    PasswordFieldsComponent,
    CardPageComponent,
    InputControlComponent,
    PhoneInputComponent,
    PasswordTickMarksComponent,
    FieldFeedbackComponent,
    CodeInputComponent,
    CineAlertStaticComponent,
    CardMovieDetailVerticalComponent,
    MovieDetailsChartsComponent,
    MovieDetailsScoreRatingsComponent,
    LoaderAnimationComponent,

    AlphanumericDirective,
    RupiahCurrencyPipe,
    DollarCurrencyPipe,
    OtpInputComponent,
    AutofocusDirective,
    OnlyNumberDirective,
    MovieDetailsIndividualChartComponent,
    MovieComparePairBoxComponent,
    VersusCircleComponent,
    MoviesCarouselComponent,
    CardAddItemButtonComponent,
    MovieGenresTextComponent,
    ScoreBoxComponent,
    ButtonTakeMeHomeComponent,
    YoutubePlayerDialogComponent,
    CompareMovieLinkComponent,
    MovieGenrePillComponent,
    CardMovieUpcomingComponent,
    CastCrewDialogComponent,
    SaveConfirmDialogComponent,
    LockedFeatureComponent,
    BoxOfficeTableComponent,
    PageTitleComponent,
    MobileFilterEntryComponent,
    MobileFilterOptionsPopupComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    InputTextModule,
    DragScrollComponent,
    DragScrollItemDirective,
    TabViewModule,
    TableModule,
    MultiSelectModule,
    CheckboxModule,
    SortAltIcon,
    SortAmountDownIcon,
    SortAmountUpAltIcon,
    TagModule,
    RouterModule,
    DropdownModule,
    StepperModule,
    PasswordModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    ToastModule,
    RadioButtonModule,
    NgOtpInputModule,
    MenuModule,
    BreadcrumbModule,
    BadgeModule,
    CardModule,
    PaginatorModule,
    InputGroupModule,
    InputGroupAddonModule,
    TooltipModule,
    CalendarModule,
    OverlayModule,
    BadgeModule,
    ChartModule,
    ProgressBarModule,
    AutoCompleteModule,
    AccordionModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    YouTubePlayerModule,
    DialogModule,
    EllipsisModule,
  ],
  exports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    MultiSelectModule,
    CheckboxModule,
    TagModule,
    RouterModule,
    DropdownModule,
    StepperModule,
    PasswordModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    ToastModule,
    RadioButtonModule,
    NgOtpInputModule,
    MenuModule,
    BreadcrumbModule,
    BadgeModule,
    CardModule,
    PaginatorModule,
    InputGroupModule,
    InputGroupAddonModule,
    ConfirmDialogModule,
    TooltipModule,
    CalendarModule,
    OverlayModule,
    BadgeModule,
    ChartModule,
    ProgressBarModule,
    AutoCompleteModule,
    AccordionModule,
    DynamicDialogModule,
    YouTubePlayerModule,
    DialogModule,
    EllipsisModule,
    LoaderAnimationComponent,

    SearchBoxComponent,
    SearchDropdownPanelComponent,
    ImageCardComponent,
    CardMovieSynopsisComponent,
    DragScrollComponent,
    DragScrollItemDirective,
    DraggableCarouselComponent,
    LatestMoviesComponent,
    SectionTitleComponent,
    SeeMoreButtonComponent,
    UpcomingMoviesComponent,
    UpcomingMovieMonthlyPanelComponent,
    CineButtonComponent,
    CineTableColumnComponent,
    CineTableComponent,
    TopBoxOfficeComponent,
    PercentChangePillComponent,
    CineFlashScoreComponent,
    RouterModule,
    BasePageComponent,
    StepperModule,
    FieldLabelComponent,
    TickMarkComponent,
    FieldErrorsComponent,
    CardPlanComponent,
    RoundedCardComponent,
    OtpInputComponent,
    BreadcrumbComponent,
    BadgeComponent,
    PasswordFieldsComponent,
    CardPageComponent,
    InputControlComponent,
    CineAlertStaticComponent,
    PhoneInputComponent,
    PasswordTickMarksComponent,
    FieldFeedbackComponent,
    CodeInputComponent,
    CardMovieDetailVerticalComponent,
    MovieDetailsIndividualChartComponent,
    MovieComparePairBoxComponent,
    VersusCircleComponent,
    MoviesCarouselComponent,
    CardAddItemButtonComponent,
    MovieGenresTextComponent,
    MovieDetailsChartsComponent,
    ScoreBoxComponent,
    MovieDetailsScoreRatingsComponent,
    CardMovieDetailHorizontalComponent,
    ButtonTakeMeHomeComponent,
    YoutubePlayerDialogComponent,
    CompareMovieLinkComponent,
    MovieGenrePillComponent,
    CardMovieUpcomingComponent,
    CastCrewDialogComponent,
    SaveConfirmDialogComponent,
    LockedFeatureComponent,
    BoxOfficeTableComponent,
    PageTitleComponent,
    MobileFilterEntryComponent,
    MobileFilterOptionsPopupComponent,

    AlphanumericDirective,
    RupiahCurrencyPipe,
    DollarCurrencyPipe,
    AutofocusDirective,
    OnlyNumberDirective,
  ],
  providers: [DialogService, ConfirmationService, CookieService, DynamicPipe, DecimalPipe, DatePipe, MessageService, RupiahCurrencyPipe]
})
export class SharedModule { }
