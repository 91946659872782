import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-add-item-button',
  templateUrl: './card-add-item-button.component.html',
  styleUrl: './card-add-item-button.component.scss'
})
export class CardAddItemButtonComponent {
  @Input() styleClass: string = '';
  @Input() label: string = '';
  @Output() onClicked: EventEmitter<any> = new EventEmitter<any>();
}
