import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-score-box',
  templateUrl: './score-box.component.html',
  styleUrl: './score-box.component.scss'
})
export class ScoreBoxComponent {
  @Input() orangeBg: boolean = true;
  @Input() score: number = 0;
}
