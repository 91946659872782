<div (click)="handleClickEvent($event)" class="cursor-pointer flex flex-col w-[215px]">
  <app-image-card
    class="w-[215px] h-[240px]"
    [url]="movie.image_title || '' "></app-image-card>
  <div class="flex flex-row pr-[8px] py-[12px]">
    <span class="grow text-movie-title pr-[8px] truncate">{{movie.title}}</span>
    <i class="pi pi-arrow-up-right text-extrabold"></i>
  </div>
  <div class=" text-description max-h-[83px] min-h-[83px]" ellipsis [ellipsis-content]="movie.description+''"></div>
</div>
